import { Button, Spinner } from "@blueprintjs/core";
import { DARK } from "@blueprintjs/core/lib/esm/common/classes";
import React from "react";
import { useTranslation } from "react-i18next";
import { ThunkDispatch } from "redux-thunk";
import styled from "styled-components";

import { CurrentCart } from "../services/productCatalogue";
import { reloadAvailability } from "../store/actions/cart";
import { ReduxRootType } from "../store/store";
import { $darkGray5, $lightGray2, $lightGray3 } from "../theme";
import CartItemTable from "./Cart/CartItemTable";
import CartTopbar from "./Cart/CartTopbar";
import OrderInfoBar from "./Cart/OrderInfoBar";
import CornerBar from "./Cart/CornerBar";
import QuickInsert from "./Cart/QuickInsert";
import { StyledOverlay } from "./ResetPassword";

const StyledCart = styled.div`
  /* box-shadow: 0px -4px 33px 0px rgba(0, 0, 0, 0.47); */
  width: 100%;
  height: 100%;
  background-color: ${$darkGray5};

  &.horizontal {
  }
`;

// import cartDeliveryStatusUnknown from '../assets/images/cartDeliveryStatusUnknown.png';
const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  min-height: 300px;

  &:nth-child(1) {
    order: 0;
    flex: 1 1 auto;
  }

  &:nth-child(2) {
    flex: 0 1 auto;
  }
`;

const StyledCartWrapper = styled(StyledFlexContainer)`
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const QuickBar = styled.div`
  position: relative;
  text-align: center;
`;
const QuickBarButtonContainer = styled.div`
  position: absolute;

  right: 0;
  top: 5px;
`;

const StyledCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  min-height: 0px;
  background-color: ${$lightGray3};
  border-left: 1px solid ${$lightGray2};
  border-right: 1px solid ${$lightGray2};

  > * {
    margin-left: -1px;
    margin-right: -1px;
  }
`;

export enum CartMode {
  orders = "orders",
  ordersEdit = "ordersEdit",
  shop = "shop"
}

export interface CartProps {
  mode: CartMode;
  order?: CurrentCart;
  title?: React.ReactElement;
  editItemIndex?: number;
  isMinimized?: boolean;
  isVertical?: boolean;
  dispatch: ThunkDispatch<ReduxRootType, {}, any>;
  // if a cart is historical it is only a log of a previous order
  // historical?: boolean;
  // if historical set and isHistoricalEdit enabled an old order becomes editable
  isHistoricalEdit?: boolean;
  onToggleMinimized?: (boolean) => void;
  isLoading?: boolean;
  availabilityLoading?: boolean;
}

export const Cart: React.FC<CartProps> = props => {
  const { t } = useTranslation();
  return (
    <StyledCart
      className={"cart " + (props.isVertical ? "vertical" : "horizontal")}
    >
      <StyledFlexContainer>
        <CartTopbar
          mode={props.mode}
          onToggleMinimized={state =>
            props.onToggleMinimized && props.onToggleMinimized(state)
          }
          isMinimized={props.isMinimized}
        />
        <StyledCartWrapper>
          <OrderInfoBar
            {...props.order}
            isVertical={props.isVertical}
            mode={props.mode}
          />
          {/* Cart Item list container */}
          <QuickBar className={DARK}>
            <CornerBar isVertical={props.isVertical} mode={props.mode} />
            {props.mode === CartMode.shop && (
              <>
                <QuickInsert />
                <QuickBarButtonContainer>
                  <Button
                    loading={props.availabilityLoading}
                    icon="refresh"
                    onClick={() => props.dispatch(reloadAvailability())}
                  >
                    {t("CHECK_AVAILABILITY")}
                  </Button>
                </QuickBarButtonContainer>
              </>
            )}
          </QuickBar>
          <StyledCartContainer>
            {/* Cart items */}
            <CartItemTable {...props} />
          </StyledCartContainer>
          {props.isLoading && (
            <StyledOverlay className={DARK}>
              <Spinner />
            </StyledOverlay>
          )}
        </StyledCartWrapper>
      </StyledFlexContainer>
    </StyledCart>
  );
};
