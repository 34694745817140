import {
    Button,
    ButtonGroup,
    Classes,
    Dialog,
    InputGroup,
    Intent,
    IPopoverProps,
    Label,
    Radio,
    RadioGroup,
    Spinner
} from "@blueprintjs/core";
import {DIALOG_BODY} from "@blueprintjs/core/lib/esm/common/classes";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";

import {
    SipShopCoreServicesVoCustomDecorEntry,
    SipShopCoreServicesVoLabeledData,
    SipShopCoreServicesVoProduct
} from "../../services/productCatalogue";
import {addToCartWithMatId} from "../../store/actions/cart";
import {getCustomDecor, selectProductGroup} from "../../store/actions/shop";
import {
    CustomDecorState,
    MultiColor,
    setCustomDecorSelectionAction,
    setCustomMultiColorAction,
    setCustomDecorProductSelectionAction
} from "../../store/reducers/shop";
import {ReduxRootType} from "../../store/store";
import {ConnectedComponent} from "../../utils";
import {DecorSuggest, productActionSuggestItemRenderer} from "./ProductItemSuggest";
import styled from "styled-components";
import FlexBoxTable, {FlexBoxColumn, FlexBoxTableProps} from "../../components/FlexTable/FlexTable";
import {$lightGray1, $lightGray3, $green2} from "../../theme";
import DefaultFlexBoxRowRenderer, {StyledFlexRow} from "../../components/FlexTable/FlexRow";
import {StyledFlexBody} from "../../components/FlexTable/FlexBody";
import {Droppable} from "react-beautiful-dnd";
import {StyledTableBodyHeader} from "./ProductTableV2";
import {TableBodyCells} from "@blueprintjs/table/lib/esnext/tableBodyCells";
import {StyledTableCell} from "../../components/FlexTable/FlexCell";

const DIN_FORMAT_FEA = 'FEA';

const mapStateToProps = (state: ReduxRootType) => ({
  customDecor: state.shop.customDecor,
  matId: state.shop.selection && state.shop.selection.group.matId,
  generatedProductId:
    state.shop.customDecor && state.shop.customDecor.productId ? state.shop.customDecor.productId : '',
  disabled: state.shop.selection
    ? !state.shop.selection.group.allowsCustomDecors
    : true,
  isComplete: !!(state.shop.customDecor && state.shop.customDecor.isComplete),
  sale: state.shop.selection && state.shop.selection.item ? state.shop.selection.item.sale : 0,
  saleFinalDate: state.shop.selection && state.shop.selection.item ? state.shop.selection.item.saleFinalDate : '',
  descr: state.shop.selection && state.shop.selection.group && state.shop.selection.group.description ? state.shop.selection.group.description : ''
});

const CustomDecorTable = styled(FlexBoxTable)`
  flex-grow: 1;
  flex-basis: 30%;
  background-color: ${$lightGray1};

  ${StyledFlexRow} {
    cursor: pointer;
    &:hover {
      background-color: ${$lightGray3} !important;
    }
  }

  ${StyledFlexBody} {
    max-height: 280px;
    overflow: auto;
  }
`;

const GreenLabel = styled.span`
  color: ${$green2};
  font-weight: bold;
`;


const getLabelledDataFromDecorList = (
  customDecor: CustomDecorState | null
): SipShopCoreServicesVoLabeledData[] => {
  if (!customDecor) {
    return [];
  }
  if (customDecor.allowsBiColor) {
      let mc = (!customDecor.multiColor || typeof customDecor.multiColor == 'undefined') ? MultiColor.uni : customDecor.multiColor;
      if ((mc === MultiColor.uni && !customDecor.uniColoredDecors) || (mc === MultiColor.biColor && !customDecor.biColoredDecors)) {
          return []
      }
      return mc === MultiColor.biColor ? customDecor.biColoredDecors.map(item => item.baseDecor) : customDecor.uniColoredDecors.map(item => item.baseDecor);
  } else {
      if (!customDecor.uniColoredDecors) {
          return []
      }
      return customDecor.uniColoredDecors.map(item => item.baseDecor);
  }
};

const getEntriesFromDecors = (
    customDecor: CustomDecorState | null,
    t: Function
): SipShopCoreServicesVoCustomDecorEntry[] => {
    if (!customDecor || customDecor.selectedDecor === null || typeof customDecor.selectedDecor === 'undefined') {
        return [];
    }
    let mc = (!customDecor.multiColor || typeof customDecor.multiColor == 'undefined') ? MultiColor.uni : customDecor.multiColor;
    if ((mc === MultiColor.uni && !customDecor.uniColoredDecors) || (mc === MultiColor.biColor && !customDecor.biColoredDecors)) {
        return []
    }
    let ret:SipShopCoreServicesVoCustomDecorEntry[] = [];
    if (mc === MultiColor.biColor) {
        for (let i = 0; i < customDecor.biColoredDecors[customDecor.selectedDecor].decors.length; i++) {
            ret.push(customDecor.biColoredDecors[customDecor.selectedDecor].decors[i])
        }
    } else {
        for (let i = 0; i < customDecor.uniColoredDecors[customDecor.selectedDecor].decors.length; i++) {
            if (customDecor.uniColoredDecors[customDecor.selectedDecor].dinFormat === DIN_FORMAT_FEA) {
                let bcData = customDecor.uniColoredDecors[customDecor.selectedDecor].decors[i].baseColor.data;
                if (customDecor.uniColoredDecors[customDecor.selectedDecor].decors[i].outsideDecor.data === '' && (bcData === '4' || bcData === '5' || bcData === '7')) {
                    customDecor.uniColoredDecors[customDecor.selectedDecor].decors[i].outsideDecor.label = t("DECOR_ALU_COVER");
                }
            }
            ret.push(customDecor.uniColoredDecors[customDecor.selectedDecor].decors[i])
        }
    }
    return ret;
};

const getAluAnnotation = (
    customDecor: CustomDecorState | null,
    selectedDecor: SipShopCoreServicesVoCustomDecorEntry[],
    remark: string
): string => {
    if (!customDecor || customDecor.selectedDecor === null || typeof customDecor.selectedDecor === 'undefined' || selectedDecor.length === 0) {
        return '';
    }
    let mc = (!customDecor.multiColor || typeof customDecor.multiColor == 'undefined') ? MultiColor.uni : customDecor.multiColor;
    if (mc === MultiColor.uni && customDecor.uniColoredDecors) {
        for (let i = 0; i < customDecor.uniColoredDecors[customDecor.selectedDecor].decors.length; i++) {
            if (customDecor.uniColoredDecors[customDecor.selectedDecor].dinFormat !== DIN_FORMAT_FEA) {
                continue;
            }
            let bcData = customDecor.uniColoredDecors[customDecor.selectedDecor].decors[i].baseColor.data;
            if (customDecor.uniColoredDecors[customDecor.selectedDecor].decors[i].outsideDecor.data === '' && (bcData === '4' || bcData === '5' || bcData === '7')) {
                return remark;
            }
        }
    }
    return '';
}

export const CustomDecorSelect: React.FC<{
  label: string;
  selectedItem?: SipShopCoreServicesVoLabeledData | null;
  disabled?: boolean;
  inline?: boolean;
  items?: SipShopCoreServicesVoLabeledData[];
  onSelect: (item: SipShopCoreServicesVoLabeledData) => void;
  popoverProps?: (Partial<IPopoverProps> & object) | undefined;
}> = ({
  label,
  selectedItem,
  disabled,
  items,
  onSelect,
  popoverProps,
  inline
}) => {
  const { t } = useTranslation();
  return (
    <Label className={inline ? Classes.INLINE : undefined}>
      <span>{t(label)}</span>
      <DecorSuggest
        inputProps={{ placeholder: t(label) }}
        selectedItem={selectedItem}
        disabled={disabled}
        items={items ? items : []}
        itemRenderer={productActionSuggestItemRenderer}
        onItemSelect={onSelect}
        inputValueRenderer={item => item.label}
        itemPredicate={(query, item) => item.label.indexOf(query) !== -1}
        popoverProps={
          !popoverProps ? { minimal: true } : { ...popoverProps, minimal: true }
        }
      />
    </Label>
  );
};

interface CustomDecorConfiguratorProps
  extends ConnectedComponent<typeof mapStateToProps> {}

const CustomDecorConfigurator: React.FC<
  CustomDecorConfiguratorProps
> = props => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const allowsBiColor = !!(props.customDecor && props.customDecor.allowsBiColor);
  const isBiColored = allowsBiColor && props.customDecor!.multiColor! === MultiColor.biColor;
  const decorSelected = !!(props.customDecor &&
    props.customDecor.selectedDecor !== null &&
    props.customDecor.selectedDecor !== undefined
  );

  const [decorData, setCustomDecors] = useState(
    getLabelledDataFromDecorList(props.customDecor)
  );

  const [selectedDecor, setSelectedDecor] = useState(
    getEntriesFromDecors(props.customDecor, t)
  );
  const handleSelectedProduct = (customDecor:CustomDecorState | null): string|null => {
      if (customDecor && customDecor.selectedCustomDecor !== undefined && customDecor.selectedCustomDecor !== null && selectedDecor.length > 0) {
          return selectedDecor[customDecor.selectedCustomDecor].matId;
      } else {
          return null;
      }
    };
  const [selectedProduct, setSelectedProduct] = useState(
    handleSelectedProduct(props.customDecor)
  );

  const [aluAnnotation, setAluAnnotation] = useState(getAluAnnotation(props.customDecor, selectedDecor, t("REMARK_ALU_COVER")));

  useEffect(() => {
    let newLds = getLabelledDataFromDecorList(props.customDecor);
    let update = false;
    if (newLds.length === decorData.length) {
            for (let i = 0; i < newLds.length; i++) {
                if (newLds[i].data !== decorData[i].data) {
                    update = true;
                    break;
                }
            }
    } else {
            update = true;
    }
    if (update) {
            setCustomDecors(newLds);
    }
    update = false;
    let newSelDecor = getEntriesFromDecors(props.customDecor, t);
    if (newSelDecor.length === selectedDecor.length) {
            for (let i = 0; i < newSelDecor.length; i++) {
                if (newSelDecor[i].matId !== selectedDecor[i].matId) {
                    update = true;
                    break;
                }
            }
    } else {
            update = true;
    }
    if (update) {
            setSelectedDecor(newSelDecor);
            if (newSelDecor.length > 0) {
                setSelectedProduct(newSelDecor[0].matId);
            } else {
                setSelectedProduct(null);
            }
            setAluAnnotation(getAluAnnotation(props.customDecor, newSelDecor, t("REMARK_ALU_COVER")));
            return;
    }
    let newSelectedProduct = handleSelectedProduct(props.customDecor);
    if ((newSelectedProduct === null && selectedProduct !== null)
            || (newSelectedProduct !== null && selectedProduct === null)
            || (newSelectedProduct !== null && selectedProduct !== null && newSelectedProduct !== selectedProduct))
    {
            setSelectedProduct(newSelectedProduct);
    }
  }, [props.customDecor]);


  function renderCellLabel(cellData:any, isSelected:boolean) {
    if (cellData) {
      return isSelected ? (<GreenLabel>{cellData.label}</GreenLabel>) : cellData.label;
    }
    return '';
  }
  function getSaleFinalDateText(sale:number) {
    if (sale > 0 && sale <= 3) {
        return t("SALE_FINAL_DATE") + " " + props.saleFinalDate;
    }
    return '';
  }
  function getSaleText(sale:number) {
      let msg = getSaleFinalDateText(sale);
      if (msg == '') {
          return msg;
      }
      if (sale == 2) {
          let msgSale = t("SALE");
          return (<>{msg}<br/>{msgSale}</>)
      }
      return (<>{msg}</>)
  }
  return (
    <div className="product-actions">
        { props.descr === '' ? ('') : ( <div className="descr" dangerouslySetInnerHTML={{
            __html: props.descr.replace(/\n/g, "<br />")
        }}></div> )}
        { props.sale === 0 ? ('') : ( <div className={props.descr === '' ? "sale" : "salewotop"}>{getSaleText(props.sale)}</div> )}
      <ButtonGroup className={"actions"}>
        <Button
          intent="success"
          disabled={props.disabled || !props.matId}
          onClick={() => {
            props.dispatch(getCustomDecor(props.matId!));
            setIsOpen(true);
          }}
        >
          {t("CUSTOM_DECOR")}
        </Button>
        <Dialog
          onClose={() => setIsOpen(false)}
          title={t("CUSTOM_DECOR")}
          isCloseButtonShown={true}
          canOutsideClickClose
          canEscapeKeyClose
          isOpen={isOpen}
          style={{width: '800px'}}
        >
          <div className={DIALOG_BODY}>
            {props.customDecor === null ? (
              <Spinner />
            ) : (
                <>
                    <RadioGroup
                        disabled={!allowsBiColor}
                        inline
                        onChange={e =>
                            props.dispatch(setCustomMultiColorAction(e.currentTarget.value as MultiColor))
                        }
                        selectedValue={
                            props.customDecor && props.customDecor.multiColor ? props.customDecor.multiColor : MultiColor.uni
                        }
                    >
                        <Radio
                            label={"Mono-Colour"}
                            value={MultiColor.uni}
                        />
                        <Radio
                            label={"Bi-Colour"}
                            value={MultiColor.biColor}
                        />
                    </RadioGroup>

                    <CustomDecorSelect
                        label={isBiColored ? "DECOR_OUTSIDE" : "DECOR"}
                        selectedItem={
                            props.customDecor && props.customDecor.selectedDecor !== undefined && props.customDecor.selectedDecor !== null
                                ? decorData[props.customDecor.selectedDecor]
                                : null
                        }
                        disabled={props.disabled}
                        items={decorData}
                        onSelect={item => {
                            if (!decorData) {
                                return;
                            }
                            const selectedDecorIndex = decorData.findIndex(
                                decor => decor === item
                            );
                            if (selectedDecorIndex !== -1) {
                                props.dispatch(setCustomDecorSelectionAction(selectedDecorIndex));
                                props.dispatch(setCustomDecorProductSelectionAction(0));
                            }
                        }}
                    />
                    <Label>
                        {t("ARTICLES")}
                        <CustomDecorTable
                            striped
                            draggable={false}
                            selectionPredicate={(cde: SipShopCoreServicesVoCustomDecorEntry) =>
                                cde.matId === selectedProduct
                            }
                            onRowClick={({rowData}) => {
                                if (!selectedDecor) {
                                    return;
                                }
                                const selectedCustomDecorIndex = selectedDecor.findIndex(decor => decor === rowData);
                                selectedCustomDecorIndex !== -1 &&
                                props.dispatch(setCustomDecorProductSelectionAction(selectedCustomDecorIndex));
                            }}
                            data={selectedDecor}
                        >
                            <FlexBoxColumn
                                dataKey="baseColor"
                                flex="0.4 0 80px"
                                name={t("BASE_COLOR")}
                            >
                                {cellProps => renderCellLabel(cellProps.data, cellProps.rowData.matId === selectedProduct)}
                            </FlexBoxColumn>
                            <FlexBoxColumn
                                dataKey="insideDecor"
                                flex="0.4 0 80px"
                                name={t("DECOR_INSIDE")}
                            >
                                {cellProps => renderCellLabel(cellProps.data, cellProps.rowData.matId === selectedProduct)}
                            </FlexBoxColumn>
                            <FlexBoxColumn
                                dataKey="outsideDecor"
                                flex="0.4 0 80px"
                                name={t("DECOR_OUTSIDE")}
                            >
                                {cellProps => renderCellLabel(cellProps.data, cellProps.rowData.matId === selectedProduct)}
                            </FlexBoxColumn>
                            <FlexBoxColumn name={t("DECOR_SEAL")} dataKey="seal" flex="0.4 0 80px">
                                {cellProps => renderCellLabel(cellProps.data, cellProps.rowData.matId === selectedProduct)}
                            </FlexBoxColumn>

                        </CustomDecorTable>
                    </Label>
                    <Label>
                        {t("ARTICLENUMBER")}
                        <InputGroup
                            value={props.generatedProductId}
                            readOnly
                            placeholder={t("ARTICLENUMBER")}
                        />
                    </Label>
                    <Label dangerouslySetInnerHTML={{
                        __html: aluAnnotation.replace(/\n/g, "<br />")
                    }}></Label>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={() => setIsOpen(false)}>
                            {t("CANCEL")}
                        </Button>
                        <Button
                            disabled={!props.isComplete}
                            onClick={() => {
                                if (props.generatedProductId && props.generatedProductId!.length > 0) {
                                    props.dispatch(
                                        addToCartWithMatId(props.generatedProductId as string, false)
                                    );
                                    setIsOpen(false);
                                }
                            }}
                            intent={Intent.SUCCESS}
                        >
                            {t("CART")}
                        </Button>
                    </div>
                </>
            )}
          </div>
        </Dialog>
      </ButtonGroup>
    </div>
  );
};

export default connect(mapStateToProps)(CustomDecorConfigurator);
