import {SipShopCoreServicesVoLabeledData} from '../../services/productCatalogue';

export const SET_USER = 'SET_USER';
export const SET_USER_LOCALE = 'SET_USER_LOCALE';
export const USER_REQUIRES_COMPANY_SELECT = 'USER_REQUIRES_COMPANY_SELECT';

export interface SetUserAction {
  type: typeof SET_USER;
  user: UserState;
}
export interface SetUserLocaleAction {
  type: typeof SET_USER_LOCALE;
  locale: string;
}

export interface UserState {
  aclGroup: string;
  brandId: string;
  companies: SipShopCoreServicesVoLabeledData[];
  companyId: string;
  companyName: string;
  companySapId: string;
  deliveryDay: string;
  email: string;
  id: string;
  locale: string;
  country: string;
  name: string;
}

export const setUserAction = (user: UserState|null) => ({type: SET_USER, user});

export const setUserLocale = (locale: string) =>
    ({type: SET_USER_LOCALE, locale});

export type ActionsUser = SetUserAction|SetUserLocaleAction;

const userReducer = (state: UserState|null = null, action: ActionsUser) => {
  switch (action.type) {
    case SET_USER:
      return action.user;
    case SET_USER_LOCALE:
      // if no user set this will not update the user object
      if (state === null) {
        return state;
      }

      return {...state, locale: action.locale};
  }
  return state;
};

export default userReducer;
