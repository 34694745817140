import {$} from './api'


export const setupSessionService = (window) => (function(window) {
  var mixinStub = function(operation, values) {
    operation.data = {
      endPoint: window.sipShopCoreServicesSessionServiceProxy.endPoint,
      server: window.sipShopCoreServicesSessionServiceProxy.server,
      arguments: values,
      complete: false,
      pending: false,
      result: null,
      exception: null,
      error: [],
      messages: [],
      ajax: null
    };
    operation.server = function(server) {
      this.data.server = server;
      return this;
    };
    operation.endPoint = function(endPoint) {
      this.data.endPoint = endPoint;
      return this;
    };
  };

  const transport = (operation, url, postData) => {
    var errorHandler = function(error) {
      operation.data.success = false;
      operation.data.error = error;
      if (operation.data._errorCallback) {
        operation.data._errorCallback(operation);
      }
    };
    operation.data.ajax = $.ajax({
      dataType: 'json',
      url: url,
      type: postData === null ? 'GET' : 'POST',
      data: JSON.stringify(postData),

      success: function(data) {
        if (data !== null) {
          operation.data.result = data.value;
          operation.data.exception = data.exception;
          operation.data.messages = data.messages;
          if (operation.data.exception !== null &&
              typeof operation.data._errorCallback == 'function') {
            errorHandler(data);
          } else if (!operation.data.exception) {
            operation.data.success = true;
            operation.data._successCallback(operation);
          }
        } else {
          errorHandler('transport threw up');
        }
        operation.data.pending = false;
      },
      error: errorHandler
    });
    return operation;
  };
  // if you do not like "sipShopCoreServicesSessionServiceProxy" as a name
  // change, then call ->clientNamespace('WhatEver.You.Like') on the server
  return {
    server:
        '',  // 'http://sip-dev'  it is better not to add your server here ;)
    endPoint:
        '/foomo/modules/SipShop.Core/services/sessionService.php/Foomo.Services.RPC/serve',
    operations: {
      /**
       * set new password for user and send the new password to the email
       * mentioned
       *
       * @param string email email address of the user
       *
       * @return boolean true if email was sent to the user (if there is no user
       *     with the email passed as parameter this function returns false,
       * too)
       */
      forgotPassword: function(email) {
        return new function(email) {
          mixinStub(this, {email: email});
          this.execute = function(successCallback) {
            this.data._successCallback = successCallback;
            var url = this.data.server + this.data.endPoint +
                '/forgotPassword' +
                '/' + encodeURIComponent(this.data.arguments.email);
            return transport(this, url);
          };
          this.error = function(errorCallback) {
            this.data._errorCallback = errorCallback;
            return this;
          };
          return this;
        }(email);
      },
      /**
       * Returns a list of LabeledData objects of all available locales (data is
       * locale id, label is text to be shown to the user)
       *
       *
       * @return SipShop\Core\Services\Vo\LabeledData[]
       */
      serviceGetLocales: function() {
        return new function() {
          mixinStub(this, {});
          this.execute = function(successCallback) {
            this.data._successCallback = successCallback;
            var url =
                this.data.server + this.data.endPoint + '/serviceGetLocales';
            return transport(this, url);
          };
          this.error = function(errorCallback) {
            this.data._errorCallback = errorCallback;
            return this;
          };
          return this;
        }();
      },
      /**
       * login
       *
       * @param string email email address of the user
       * @param string password password
       * @param integer companyId id of the company the user is to be associated
       *     to (zero if there is only one company)
       *
       * @return SipShop\Core\Services\Vo\LoginUser
       */
      serviceLogin: function(email, password, companyId) {
        return new function(email, password, companyId) {
          mixinStub(
              this, {email: email, password: password, companyId: companyId});
          this.execute = function(successCallback) {
            this.data._successCallback = successCallback;
            var url = this.data.server + this.data.endPoint + '/serviceLogin' +
                '/' + encodeURIComponent(this.data.arguments.email) + '/' +
                encodeURIComponent(this.data.arguments.password) + '/' +
                encodeURIComponent(this.data.arguments.companyId);
            return transport(this, url);
          };
          this.error = function(errorCallback) {
            this.data._errorCallback = errorCallback;
            return this;
          };
          return this;
        }(email, password, companyId);
      },
      /**
       * logout
       *
       *
       * @return boolean true
       */
      serviceLogout: function() {
        return new function() {
          mixinStub(this, {});
          this.execute = function(successCallback) {
            this.data._successCallback = successCallback;
            var url = this.data.server + this.data.endPoint + '/serviceLogout';
            return transport(this, url);
          };
          this.error = function(errorCallback) {
            this.data._errorCallback = errorCallback;
            return this;
          };
          return this;
        }();
      },
      /**
       * Set current users locale
       *
       * @param string locale
       *
       * @return boolean true if locale could be set
       */
      serviceSetLocale: function(locale) {
        return new function(locale) {
          mixinStub(this, {locale: locale});
          this.execute = function(successCallback) {
            this.data._successCallback = successCallback;
            var url = this.data.server + this.data.endPoint +
                '/serviceSetLocale' +
                '/' + encodeURIComponent(this.data.arguments.locale);
            return transport(this, url);
          };
          this.error = function(errorCallback) {
            this.data._errorCallback = errorCallback;
            return this;
          };
          return this;
        }(locale);
      },
      /**
       * Set current users password
       *
       * @param string oldPassword current password
       * @param string newPassword new password
       *
       * @return boolean true if successful
       */
      serviceSetPassword: function(oldPassword, newPassword) {
        return new function(oldPassword, newPassword) {
          mixinStub(this, {oldPassword: oldPassword, newPassword: newPassword});
          this.execute = function(successCallback) {
            this.data._successCallback = successCallback;
            var url = this.data.server + this.data.endPoint +
                '/serviceSetPassword' +
                '/' + encodeURIComponent(this.data.arguments.oldPassword) +
                '/' + encodeURIComponent(this.data.arguments.newPassword);
            return transport(this, url);
          };
          this.error = function(errorCallback) {
            this.data._errorCallback = errorCallback;
            return this;
          };
          return this;
        }(oldPassword, newPassword);
      }

    }
  };
})(window);