export const $black = '#10161a';
export const $darkGray1 = '#182026';
export const $darkGray2 = '#202b33';
export const $darkGray3 = '#293742';
export const $darkGray4 = '#30404d';
export const $darkGray5 = '#394b59';
export const $gray1 = '#5c7080';
export const $gray2 = '#738694';
export const $gray3 = '#8a9ba8';
export const $gray4 = '#a7b6c2';
export const $gray5 = '#bfccd6';
export const $lightGray1 = '#ced9e0';
export const $lightGray2 = '#d8e1e8';
export const $lightGray3 = '#e1e8ed';
export const $lightGray4 = '#ebf1f5';
export const $lightGray5 = '#f5f8fa';
export const $white = '#ffffff';
export const $blue1 = '#0e5a8a';
export const $blue2 = '#106ba3';
export const $blue3 = '#137cbd';
export const $blue4 = '#2b95d6';
export const $blue5 = '#48aff0';
export const $green1 = '#0a6640';
export const $green2 = '#0d8050';
export const $green3 = '#0f9960';
export const $green4 = '#15b371';
export const $green5 = '#3dcc91';
export const $orange1 = '#a66321';
export const $orange2 = '#bf7326';
export const $orange3 = '#d9822b';
export const $orange4 = '#f29d49';
export const $orange5 = '#ffb366';
export const $red1 = '#a82a2a';
export const $red2 = '#c23030';
export const $red3 = '#db3737';
export const $red4 = '#f55656';
export const $red5 = '#ff7373';
export const $vermilion1 = '#9e2b0e';
export const $vermilion2 = '#b83211';
export const $vermilion3 = '#d13913';
export const $vermilion4 = '#eb532d';
export const $vermilion5 = '#ff6e4a';
export const $rose1 = '#a82255';
export const $rose2 = '#c22762';
export const $rose3 = '#db2c6f';
export const $rose4 = '#f5498b';
export const $rose5 = '#ff66a1';
export const $violet1 = '#5c255c';
export const $violet2 = '#752f75';
export const $violet3 = '#8f398f';
export const $violet4 = '#a854a8';
export const $violet5 = '#c274c2';
export const $indigo1 = '#5642a6';
export const $indigo2 = '#634dbf';
export const $indigo3 = '#7157d9';
export const $indigo4 = '#9179f2';
export const $indigo5 = '#ad99ff';
export const $cobalt1 = '#1f4b99';
export const $cobalt2 = '#2458b3';
export const $cobalt3 = '#2965cc';
export const $cobalt4 = '#4580e6';
export const $cobalt5 = '#669eff';
export const $turquoise1 = '#008075';
export const $turquoise2 = '#00998c';
export const $turquoise3 = '#00b3a4';
export const $turquoise4 = '#14ccbd';
export const $turquoise5 = '#2ee6d6';
export const $forest1 = '#1d7324';
export const $forest2 = '#238c2c';
export const $forest3 = '#29a634';
export const $forest4 = '#43bf4d';
export const $forest5 = '#62d96b';
export const $lime1 = '#728c23';
export const $lime2 = '#87a629';
export const $lime3 = '#9bbf30';
export const $lime4 = '#b6d94c';
export const $lime5 = '#d1f26d';
export const $gold1 = '#a67908';
export const $gold2 = '#bf8c0a';
export const $gold3 = '#d99e0b';
export const $gold4 = '#f2b824';
export const $gold5 = '#ffc940';
export const $sepia1 = '#63411e';
export const $sepia2 = '#7d5125';
export const $sepia3 = '#96622d';
export const $sepia4 = '#b07b46';
export const $sepia5 = '#c99765';