import { Button, ButtonGroup } from "@blueprintjs/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";

import { exportCart } from "../../store/actions/cart";
import { exportOrder } from "../../store/actions/order";
import { ReduxRootType } from "../../store/store";
import { $darkGray2 } from "../../theme";
import { ConnectedComponent } from "../../utils";
import { CartMode } from "../Cart";
import CartImportDialog from "./CartImportDialog";
import DocumentsDialog from "./DocumentsDialog";

const StyledCornerBar = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: ${$darkGray2};
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  button {
    padding: 16px 15px;
    margin: 0;
  }
  button,
  .bp3-icon {
    color: #fff !important;
  }
  button.bp3-disabled .bp3-button-text,
  button.bp3-disabled .bp3-icon {
    color: rgba(255, 255, 255, 0.2) !important;
  }
`;

const mapStateToProps = (state: ReduxRootType) => ({
  orderId: state.orders.detail && state.orders.detail.id,
  documents: state.orders.detail && state.orders.detail.documents
});

interface CartCornerButtonRowProps
  extends ConnectedComponent<typeof mapStateToProps> {
  isVertical?: boolean;
  mode: CartMode;
}

const CartCornerButtonRow: React.FC<CartCornerButtonRowProps> = props => {
  const { t } = useTranslation();
  return (
    <StyledCornerBar
      className="cornerbar"
      style={{ float: props.isVertical ? "left" : "none" }}
    >
      <ButtonGroup className="export-actions">
        {props.mode !== CartMode.shop ? (
          <>
            <Button
              minimal
              disabled={!props.orderId}
              onClick={() => props.dispatch(exportOrder(props.orderId!))}
              icon="export"
            >
              {t("EXPORT")}
            </Button>
            <Button
              minimal
              disabled={!props.orderId}
              onClick={() => props.dispatch(exportOrder(props.orderId!, "PDF"))}
              icon="export"
            >
              {t("EXPORT_PDF")}
            </Button>
            <DocumentsDialog />
          </>
        ) : (
          <>
            <CartImportDialog />
            <Button
              onClick={() => props.dispatch(exportCart())}
              minimal
              icon="export"
            >
              {t("EXPORT")}
            </Button>
          </>
        )}
      </ButtonGroup>
    </StyledCornerBar>
  );
};

export default connect(mapStateToProps)(CartCornerButtonRow);
