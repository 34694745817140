import { IconName, Intent } from "@blueprintjs/core";

export const CLEAR_TOASTS = "CLEAT_TOASTS";
export const ADD_TOAST = "ADD_TOAST";

export interface ToastType {
  intent?: Intent;
  message: string;
  icon?: IconName;
  // icon for an action
  actionIcon?: IconName;
  // action to be dispatched on action click
  actionAction?: string;
  // action data will be passed to the action
  actionPayload?: any;
  // weather the message should be localized at render time with i18next
  localize?: boolean;
}

export type ToastState = Array<ToastType>;

export interface AddToastAction {
  type: typeof ADD_TOAST;
  payload: ToastType;
}

export interface ClearToastsAction {
  type: typeof CLEAR_TOASTS;
}

export const addErrorToast = (message: string, localize?: boolean) =>
  addToast({
    message,
    localize,
    intent: "danger"
  });

export const addToast = (toast: ToastType) => ({
  type: ADD_TOAST,
  payload: toast
});

export const clearToasts = () => ({ type: CLEAR_TOASTS });

const toastReducer = (
  state: ToastState = [],
  action: ClearToastsAction | AddToastAction
) => {
  switch (action.type) {
    case ADD_TOAST:
      return [action.payload, ...state];
    case CLEAR_TOASTS:
      return [];
  }
  return state;
};

export default toastReducer;
