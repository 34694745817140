import React, { useState } from "react";
import { Button, Dialog } from "@blueprintjs/core";
import {
  DIALOG_BODY,
  DIALOG_FOOTER_ACTIONS,
  DIALOG_FOOTER
} from "@blueprintjs/core/lib/esm/common/classes";
import styled from "styled-components";

interface BaseConfirmProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmLabel: string;
  cancelLabel: string;
  confirmIntent?: "primary" | "none" | "success" | "warning" | "danger";
  cancelIntent?: "primary" | "none" | "success" | "warning" | "danger";
  msg: string;
  title?: string;
}

interface WithLabel extends BaseConfirmProps {
  label: string;
}

interface WithTrigger extends BaseConfirmProps {
  trigger: React.ReactElement<{ onClick?: (e: any) => void }>;
}

const StyledConfirmButton = styled(Button)`
  min-width: 80px;
`;

const Confirm: React.FC<WithLabel | WithTrigger> = props => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {(props as WithLabel).label ? (
        <Button onClick={() => setIsOpen(true)}>
          {(props as WithLabel).label}
        </Button>
      ) : (
        <span onClick={() => setIsOpen(true)}>
        {(props as WithTrigger).trigger}
        </span>
      )}
      <Dialog
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          if (props.onCancel) {
            props.onCancel();
          }
        }}
        title={props.title}
      >
        <div className={DIALOG_BODY}>{props.msg}</div>
        <div className={DIALOG_FOOTER}>
          <div className={DIALOG_FOOTER_ACTIONS}>
            <StyledConfirmButton
              intent={props.confirmIntent ? props.confirmIntent : "primary"}
              onClick={() => {
                if (props.onConfirm) {
                  props.onConfirm();
                }
                setIsOpen(false);
              }}
            >
              {props.confirmLabel}
            </StyledConfirmButton>
            <StyledConfirmButton
              intent={props.cancelIntent}
              onClick={() => {
                if (props.onCancel) {
                  props.onCancel();
                }
                setIsOpen(false);
              }}
            >
              {props.cancelLabel}
            </StyledConfirmButton>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Confirm;
