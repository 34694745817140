import {$} from './api';
// http://sip-dev/foomo/modulesVar/Foomo.Services/js/Proxy.js
/*

  !!! DO NOT EDIT THIS FILE / CODE IT WAS GENERATED !!!

  generated @2019-09-16 20:55:01
  on http://sip-dev
  THUS, if you this code is missing operations - just regenerate it.

  Oh and if you do not like this code -> fix the generator ;)


*/

export const setupOrderService = window => (function(window) {
  var mixinStub = function(operation, args) {
    operation.data = {
      endPoint: window.sipShopCoreServicesOrderProxy.endPoint,
      server: window.sipShopCoreServicesOrderProxy.server,
      arguments: args,
      complete: false,
      pending: false,
      result: null,
      exception: null,
      error: [],
      messages: [],
      ajax: null
    };
    operation.server = function(server) {
      this.data.server = server;
      return this;
    };
    operation.endPoint = function(endPoint) {
      this.data.endPoint = endPoint;
      return this;
    };
  };

  var transport = function(operation, url, postData) {
    var errorHandler = function(error) {
      operation.data.success = false;
      operation.data.error = error;
      if (operation.data._errorCallback) {
        operation.data._errorCallback(operation);
      }
    };
    operation.data.ajax = $.ajax({
      dataType: 'json',
      url: url,
      type: postData === null ? 'GET' : 'POST',
      data: JSON.stringify(postData),

      success: function(data) {
        if (data !== null) {
          operation.data.result = data.value;
          operation.data.exception = data.exception;
          operation.data.messages = data.messages;
          if (operation.data.exception !== null &&
              typeof operation.data._errorCallback == 'function') {
            errorHandler(data);
          } else if (!operation.data.exception) {
            operation.data.success = true;
            operation.data._successCallback(operation);
          }
        } else {
          errorHandler('transport threw up');
        }
        operation.data.pending = false;
      },
      error: errorHandler
    });
    return operation;
  };
  // if you do not like "sipShopCoreServicesOrderProxy" as a name change,
  // then call ->clientNamespace('WhatEver.You.Like') on the server
  return {
    server:
        '',  // 'http://sip-dev'  it is better not to add your server here ;)
    endPoint:
        '/foomo/modules/SipShop.Core/services/order.php/Foomo.Services.RPC/serve',
    operations: {
      /**
       * Exports the order as a CSV file
       *
       * @param integer orderId identifier of the order to be retrieved
       *
       * @return SipShop\Core\Services\Vo\DocumentDownload metadata of the
       *     document
       */
      exportOrder: function(orderId) {
        return new (function(orderId) {
          mixinStub(this, {orderId: orderId});
          this.execute = function(successCallback) {
            this.data._successCallback = successCallback;
            var url = this.data.server + this.data.endPoint + '/exportOrder' +
                '/' + encodeURIComponent(this.data.arguments.orderId);
            return transport(this, url);
          };
          this.error = function(errorCallback) {
            this.data._errorCallback = errorCallback;
            return this;
          };
          return this;
        })(orderId);
      },
      /**
       * Exports the order as a PDF file
       *
       * @param integer orderId identifier of the order to be retrieved
       *
       * @return SipShop\Core\Services\Vo\DocumentDownload metadata of the PDF
       *     file
       */
      exportOrderPdf: function(orderId) {
        return new (function(orderId) {
          mixinStub(this, {orderId: orderId});
          this.execute = function(successCallback) {
            this.data._successCallback = successCallback;
            var url = this.data.server + this.data.endPoint +
                '/exportOrderPdf' +
                '/' + encodeURIComponent(this.data.arguments.orderId);
            return transport(this, url);
          };
          this.error = function(errorCallback) {
            this.data._errorCallback = errorCallback;
            return this;
          };
          return this;
        })(orderId);
      },
      /**
       * Returns the complete order VO of the order with the id passed as
       * parameter
       *
       * @param integer orderId identifier of the order to be retrieved
       *
       * @return SipShop\Core\Services\Vo\Order
       */
      getOrderDetails: function(orderId) {
        return new (function(orderId) {
          mixinStub(this, {orderId: orderId});
          this.execute = function(successCallback) {
            this.data._successCallback = successCallback;
            var url = this.data.server + this.data.endPoint +
                '/getOrderDetails' +
                '/' + encodeURIComponent(this.data.arguments.orderId);
            return transport(this, url);
          };
          this.error = function(errorCallback) {
            this.data._errorCallback = errorCallback;
            return this;
          };
          return this;
        })(orderId);
      },
      /**
       * Returns the VOs of the orders (but item and document arrays are empty)
       *
       *
       * @return SipShop\Core\Services\Vo\Order[]
       */
      getOrders: function(matId) {
        return new (function() {
          mixinStub(this, {});
          this.execute = function(successCallback) {
            this.data._successCallback = successCallback;
            var url = this.data.server + this.data.endPoint + '/getOrders';
            if (matId && matId !== '') {
              url = url + '/' + encodeURIComponent(matId)
            }
            return transport(this, url);
          };
          this.error = function(errorCallback) {
            this.data._errorCallback = errorCallback;
            return this;
          };
          return this;
        })();
      },
      /**
       * Sends a modification email concerning the order with the id $orderId
       *
       * @param integer orderId identifier of the order to be retrieved
       * @param string subject subject of the email
       * @param string msg the message body of the email
       *
       * @return boolean true if email has been sent
       */
      modifyOrderViaEmail: function(orderId, subject, msg) {
        return new (function(orderId, subject, msg) {
          mixinStub(this, {orderId: orderId, subject: subject, msg: msg});
          this.execute = function(successCallback) {
            this.data._successCallback = successCallback;
            var url = this.data.server + this.data.endPoint +
                '/modifyOrderViaEmail' +
                '/' + encodeURIComponent(this.data.arguments.orderId) + '/' +
                encodeURIComponent(this.data.arguments.subject) + '/' +
                encodeURIComponent(this.data.arguments.msg);
            return transport(this, url);
          };
          this.error = function(errorCallback) {
            this.data._errorCallback = errorCallback;
            return this;
          };
          return this;
        })(orderId, subject, msg);
      }
    }
  };
})(window);
