import { $ } from "./api";

export const setupCartService = window =>
  (function(window) {
    var mixinStub = function(operation, args) {
      operation.data = {
        endPoint: window.sipShopCoreServicesCartProxy.endPoint,
        server: window.sipShopCoreServicesCartProxy.server,
        arguments: args,
        complete: false,
        pending: false,
        result: null,
        exception: null,
        error: [],
        messages: [],
        ajax: null
      };
      operation.server = function(server) {
        this.data.server = server;
        return this;
      };
      operation.endPoint = function(endPoint) {
        this.data.endPoint = endPoint;
        return this;
      };
    };

    var transport = function(operation, url, postData) {
      var errorHandler = function(error) {
        operation.data.success = false;
        operation.data.error = error;
        if (operation.data._errorCallback) {
          operation.data._errorCallback(operation);
        }
      };
      operation.data.ajax = $.ajax({
        dataType: "json",
        url: url,
        type: postData === null ? "GET" : "POST",
        data: JSON.stringify(postData),

        success: function(data) {
          if (data !== null) {
            operation.data.result = data.value;
            operation.data.exception = data.exception;
            operation.data.messages = data.messages;
            if (
              operation.data.exception !== null &&
              typeof operation.data._errorCallback == "function"
            ) {
              errorHandler(data);
            } else if (!operation.data.exception) {
              operation.data.success = true;
              operation.data._successCallback(operation);
            }
          } else {
            errorHandler("transport threw up");
          }
          operation.data.pending = false;
        },
        error: errorHandler
      });
      return operation;
    };
    // if you do not like "sipShopCoreServicesCartProxy" as a name change,
    // then call ->clientNamespace('WhatEver.You.Like') on the server
    return {
      server: "", // 'http://sip-dev'  it is better not to add your server here ;)
      endPoint:
        "/foomo/modules/SipShop.Core/services/cart.php/Foomo.Services.RPC/serve",
      operations: {
        /**
         * Checks the availability of the items in the cart identified by the
         * $cartId
         *
         *
         * @return SipShop\Core\Services\Vo\Cart
         */
        getAvailability: function() {
          return new (function() {
            mixinStub(this, {});
            this.execute = function(successCallback) {
              this.data._successCallback = successCallback;
              var url =
                this.data.server + this.data.endPoint + "/getAvailability";
              return transport(this, url);
            };
            this.error = function(errorCallback) {
              this.data._errorCallback = errorCallback;
              return this;
            };
            return this;
          })();
        },
        /**
         * Returns localized informations on the cart environment
         *
         *
         * @return SipShop\Core\Services\Vo\CartEnv
         */
        getCartEnvironment: function() {
          return new (function() {
            mixinStub(this, {});
            this.execute = function(successCallback) {
              this.data._successCallback = successCallback;
              var url =
                this.data.server + this.data.endPoint + "/getCartEnvironment";
              return transport(this, url);
            };
            this.error = function(errorCallback) {
              this.data._errorCallback = errorCallback;
              return this;
            };
            return this;
          })();
        },
        /**
         * Returns the VO of the current cart
         *
         *
         * @return SipShop\Core\Services\Vo\Cart
         */
        getCurrentCart: function() {
          return new (function() {
            mixinStub(this, {});
            this.execute = function(successCallback) {
              this.data._successCallback = successCallback;
              var url =
                this.data.server + this.data.endPoint + "/getCurrentCart";
              return transport(this, url);
            };
            this.error = function(errorCallback) {
              this.data._errorCallback = errorCallback;
              return this;
            };
            return this;
          })();
        },
        /**
         * Calculates the prices of the items in the cart (only for authorized
         * customers)
         *
         *
         * @return SipShop\Core\Services\Vo\Cart
         */
        getPrices: function() {
          return new (function() {
            mixinStub(this, {});
            this.execute = function(successCallback) {
              this.data._successCallback = successCallback;
              var url = this.data.server + this.data.endPoint + "/getPrices";
              return transport(this, url);
            };
            this.error = function(errorCallback) {
              this.data._errorCallback = errorCallback;
              return this;
            };
            return this;
          })();
        },
        /**
         * Modifies the cart as passed in the VO
         * (i.e. name can be set, new items are added, items are changed in the
         * position in the list, item amounts are changed, favoured delivery date,
         * delivery address, completeDelivery are modified)
         *
         * @param SipShop\Core\Services\Vo\Cart cartNew
         *
         * @return SipShop\Core\Services\Vo\Cart
         */
        modifyCart: function(cartNew) {
          return new (function(cartNew) {
            mixinStub(this, { cartNew: cartNew });
            this.execute = function(successCallback) {
              this.data._successCallback = successCallback;
              var url = this.data.server + this.data.endPoint + "/modifyCart";
              var postData = { cartNew: this.data.arguments.cartNew };
              return transport(this, url, postData);
            };
            this.error = function(errorCallback) {
              this.data._errorCallback = errorCallback;
              return this;
            };
            return this;
          })(cartNew);
        },
        /**
         * Order the cart passed as parameter
         * this behaviour is wanted, but I think it is at least worth leaving a
         * comment:
         * - passing $cart to order() and calling modifyCart from here may result
         * in an order, where some items may not have passed the checks in
         * modifyCart!
         * - allowing to call order() without forcing checkOrder() may result in
         * an order, where some items or their count were modified by SAP in
         * checkOrder! both without giving the customer any opportunity to adjust
         * his cart.
         *
         * @param SipShop\Core\Services\Vo\Cart cart
         *
         * @return SipShop\Core\Services\Vo\Order
         */
        order: function(cart) {
          return new (function(cart) {
            mixinStub(this, { cart: cart });
            this.execute = function(successCallback) {
              this.data._successCallback = successCallback;
              var url = this.data.server + this.data.endPoint + "/order";
              var postData = { cart: this.data.arguments.cart };
              return transport(this, url, postData);
            };
            this.error = function(errorCallback) {
              this.data._errorCallback = errorCallback;
              return this;
            };
            return this;
          })(cart);
        },
        /**
         * Deletes the current cart (if there is one) and returns a empty cart VO
         *
         * @param boolean logRemovedItems if true the removed items are logged in
         *     table removed_items
         *
         * @return SipShop\Core\Services\Vo\Cart $cart an empty cart
         */
        resetCart: function(logRemovedItems) {
          return new (function(logRemovedItems) {
            mixinStub(this, { logRemovedItems: logRemovedItems });
            this.execute = function(successCallback) {
              this.data._successCallback = successCallback;
              var url =
                this.data.server +
                this.data.endPoint +
                "/resetCart/" +
                encodeURIComponent(this.data.arguments.logRemovedItems);
              return transport(this, url);
            };
            this.error = function(errorCallback) {
              this.data._errorCallback = errorCallback;
              return this;
            };
            return this;
          })(logRemovedItems);
        },
        /**
         * Processes the CSV file given in $fileSpec and creates the current cart
         * from it
         *
         * @param SipShop\Core\Services\Vo\DocumentUpload fileSpec CSV file
         *
         * @return SipShop\Core\Services\Vo\Cart
         */
        uploadCartData: function(fileSpec) {
          return new (function(fileSpec) {
            mixinStub(this, { fileSpec: fileSpec });
            this.execute = function(successCallback) {
              this.data._successCallback = successCallback;
              var url =
                this.data.server + this.data.endPoint + "/uploadCartData";
              var postData = { ref: this.data.arguments.fileSpec };
              return transport(this, url, postData);
            };
            this.error = function(errorCallback) {
              this.data._errorCallback = errorCallback;
              return this;
            };
            return this;
          })(fileSpec);
        }
      }
    };
  })(window);
