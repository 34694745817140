import {
  Alignment,
  Button,
  Classes,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
  Popover,
  Position
} from "@blueprintjs/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import logo from "./assets/images/sip-logo.svg";
import CartButton from "./components/CartButton";
import LanguageDropdown from "./components/LanguageDropdown";
import UserMenu from "./components/UserMenu";
import { LanguageCode, setLanguage } from "./store/actions/user";
import { ProductSelectionState } from "./store/reducers/shop";
import { ReduxRootType } from "./store/store";
import { ConnectedComponent } from "./utils";

export const getSelectionURL = (selection?: ProductSelectionState) =>
  selection
    ? `/${selection.item ? "product" : "product-group"}/${
        selection.item ? selection.item.id : selection.group.id
      }`
    : "/";

const mapStateToProps = (state: ReduxRootType) => ({
  isLoggedIn: state.user !== null && state.user.id !== "",
  companyName: state.user !== null ? state.user.companyName : "",
  cartItems:
    state.cart.current && state.cart.current.items
      ? state.cart.current.items
      : [],
  selectionURL: getSelectionURL(state.shop.selection),
  isShopES: state.user !== null && state.user.country === "ES"
});

interface HeaderProps extends ConnectedComponent<typeof mapStateToProps> {}

const StyledNavLink = styled(NavLink)`
  &.active button {
    background-color: #0d8050;
    color: #fff;
  }
  &.active button .bp3-icon {
    color: #fff;
  }
`;

const shopPathMatcher = (match, location): boolean => {
  return (
    location.pathname.indexOf("product-group/") !== -1 ||
    location.pathname.indexOf("product/") !== -1 ||
    location.pathname === "/"
  );
};

const Header = (props: HeaderProps) => {
  const { t, i18n } = useTranslation();
  // const [isVideoOpen, setVideoGuideOpen] = useState(false);
  const isLoggedIn = props.isLoggedIn;

  return (
    <header className="app-header">
      <Navbar>
        <NavbarGroup align={Alignment.LEFT}>
          <NavbarHeading>
            <NavLink to={props.selectionURL}>
              <img alt="Salamander" src={logo} />
            </NavLink>
          </NavbarHeading>
          {isLoggedIn && (
            <>
              <NavbarDivider />
              <StyledNavLink to={props.selectionURL} isActive={shopPathMatcher}>
                <Button
                  className={Classes.MINIMAL}
                  // icon="home"
                  text={t("SHOP")}
                />
              </StyledNavLink>
              <StyledNavLink to={`/orders`}>
                <Button
                  className={Classes.MINIMAL}
                  // icon="document"
                  text={t("ORDERS")}
                />
              </StyledNavLink>
              <NavbarDivider className="desktop-help" />
              <Button
                onClick={() => openUserManual(i18n.language as LanguageCode)}
                className={Classes.MINIMAL + " desktop-help"}
                icon={
                  <img
                    style={{ height: 20 }}
                    alt={t("USER_MANUAL")}
                    src={"/images/benutzerhandbuch.jpg"}
                  />
                }
                text={t("USER_MANUAL")}
              />
                <Button
                    onClick={() => openColorBooklet(props.isShopES, i18n.language as LanguageCode)}
                    className={Classes.MINIMAL + " desktop-help"}
                    icon={
                        <img
                            style={{ height: 20 }}
                            alt={t("COLOR_BOOKLET")}
                            src={"/images/benutzerhandbuch.jpg"}
                        />
                    }
                    text={t("COLOR_BOOKLET")}
                />

              <Popover className="mobile-help" position={Position.BOTTOM}>
                <Button
                  className={Classes.MINIMAL}
                  icon="help"
                  text={t("HELP")}
                />
                {/* <GuideMenu onOpenVideo={() => setVideoGuideOpen(true)} /> */}
              </Popover>
              {/* <Dialog
                style={{ paddingBottom: 0, width: "70vw" }}
                canEscapeKeyClose
                canOutsideClickClose
                onClose={() => setVideoGuideOpen(false)}
                isOpen={isVideoOpen}
              >
                <video autoPlay preload="none" controls width="100%">
                  <source src="/videoguide.mp4" type="video/mp4" />
                </video>
              </Dialog> */}
            </>
          )}
        </NavbarGroup>
        <NavbarGroup align={Alignment.RIGHT}>
          <LanguageDropdown
            onLanguageChanged={lang => props.dispatch(setLanguage(lang))}
          />
          {isLoggedIn && (
            <>
              <NavbarDivider />
              <CartButton />
              <NavbarDivider />
              <UserMenu />
            </>
          )}
        </NavbarGroup>
      </Navbar>
    </header>
  );
};

export default connect(mapStateToProps)(Header);

/**
 * Utils
 */

// opens manual in new tab
const openUserManual = (language: LanguageCode) => {
  switch (language) {
    case LanguageCode.DE:
    case LanguageCode.PL:
    case LanguageCode.EN:
      window.open(`/guides/SIPLeitfaden_${language}.pdf`);
      return;
    default:
      window.open(`/guides/SIPLeitfaden_${LanguageCode.EN}.pdf`);
      return;
  }
};

// opens color booklet in new tab
const openColorBooklet = (isShopES:boolean, language: LanguageCode) => {
    if (isShopES) {
        if (language === LanguageCode.PT) {
            window.open(`/online-shop/decors_colours_pt.pdf`);
        } else {
            window.open(`/online-shop/decors_colours_es.pdf`);
        }
    } else {
        window.open(`/online-shop/decors_colours.pdf`);
    }
    return;
};

// guide menu dropdown
// DISABELD: since only one guide element is currently present
// const GuideMenu: React.FC<{ onOpenVideo: () => void }> = props => {
//   const { t, i18n } = useTranslation();
//   return (
//     <Menu>
//       <MenuItem
//         onClick={() => openUserManual(i18n.language as LanguageCode)}
//         icon={
//           <img
//             style={{ height: 20 }}
//             alt={t("VIDEOGUIDE")}
//             src={"/images/benutzerhandbuch.jpg"}
//           />
//         }
//         text={t("USER_MANUAL")}
//       />
//       <MenuItem
//         onClick={props.onOpenVideo}
//         icon={
//           <img
//             style={{ height: 20 }}
//             alt={t("VIDEOGUIDE")}
//             src={"/images/Videoleitfaden-1.jpg"}
//           />
//         }
//         text={t("VIDEOGUIDE")}
//       />
//     </Menu>
//   );
// };
