import { Button, Dialog, InputGroup, Label, Spinner } from "@blueprintjs/core";
import {
  DIALOG_BODY,
  DIALOG_FOOTER,
  DIALOG_FOOTER_ACTIONS
} from "@blueprintjs/core/lib/esm/common/classes";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";

import { CurrentCart } from "../../services/productCatalogue";
import {
  placeOrder,
  reloadAvailability,
  resetCartToOrder
} from "../../store/actions/cart";
import { setCartMinimizedAction } from "../../store/reducers/bottomCart";
import { setOrderEdit } from "../../store/reducers/order";
import { ReduxRootType } from "../../store/store";
import { $darkGray3, $darkGray5, $lightGray4 } from "../../theme";
import { ConnectedComponent } from "../../utils";
import { CartMode } from "../Cart";
import { StyledOverlay } from "../ResetPassword";
import CartOrderEditEmailDialog from "./CartOrderEditEmailDialog";

const mapStateToProps = (state: ReduxRootType) => ({
  selectedOrder: state.orders.detail && state.orders.detail,
  orderInProgress: state.cart.orderInProcess,
  isCartEmpty: state.cart.current ? state.cart.current.items.length === 0 : true
});

interface CartTopbarProps extends ConnectedComponent<typeof mapStateToProps> {
  onToggleMinimized?: (mini: boolean) => void;
  mode: CartMode;
  isMinimized?: boolean;
  order?: CurrentCart;
}

const StyledTopBarButton = styled(Button)`
  position: relative;
  z-index: 2 !important;
`;

const StyledTopBarDialog = styled(CartOrderEditEmailDialog)`
  position: relative;
  /* index required to be above dragger component */
  z-index: 2 !important;
`;

const StyledButtonGroup = styled.div`
  /* index required to be above dragger component */
  .bp3-button {
    position: relative;
    z-index: 2 !important;
  }
`;

const StyledRightButtonGroup = styled(StyledButtonGroup)`
  min-width: 200px;
  float: right;

  .bp3-button {
    margin-left: 5px;
  }
`;

const StyledNavbar = styled.div`
  padding: 15px 10px;
  background: linear-gradient(${$darkGray5}, ${$darkGray3});
  border-bottom: 1px solid ${$darkGray3};
`;

const CartOrderPreviewTopbar: React.FC<CartTopbarProps> = props => {
  const { t } = useTranslation();
  const dispatch = props.dispatch;
  const onReorder = useCallback(
    () =>
      props.selectedOrder && dispatch(resetCartToOrder(props.selectedOrder)),
    [props.selectedOrder, dispatch]
  );
  const onEdit = useCallback(() => dispatch(setOrderEdit(true)), [dispatch]);
  const onEditCancel = useCallback(() => dispatch(setOrderEdit(false)), [
    dispatch
  ]);
  return (
    <>
      <StyledButtonGroup style={{ float: "left" }}>
        <StyledTopBarButton
          intent={"primary"}
          onClick={() =>
            props.onToggleMinimized &&
            props.onToggleMinimized(!props.isMinimized)
          }
          icon={props.isMinimized ? "maximize" : "minimize"}
        >
          {t("DETAILS")}
        </StyledTopBarButton>
      </StyledButtonGroup>
      <StyledRightButtonGroup>
        {props.mode === CartMode.orders && (
          <>
            <Button
              intent={"primary"}
              disabled={!props.selectedOrder}
              onClick={onEdit}
              icon="edit"
            >
              {t("EDIT")}
            </Button>
            <Button
              intent={"primary"}
              disabled={!props.selectedOrder}
              onClick={onReorder}
              icon="repeat"
            >
              {t("REORDER")}
            </Button>
          </>
        )}
        {props.mode === CartMode.ordersEdit && (
          <>
            <StyledTopBarButton
              disabled={!props.selectedOrder}
              onClick={onEditCancel}
            >
              {t("CANCEL")}
            </StyledTopBarButton>
            <StyledTopBarDialog disableOnNoChanges={true} />
            <StyledTopBarDialog
              disableOnNoChanges={false}
              label={t("DELETE_ORDER")}
              icon="disable"
              intent="danger"
              cancelOrder={true}
            />
          </>
        )}
      </StyledRightButtonGroup>
    </>
  );
};

const StyledDialogOverlay = styled(StyledOverlay)`
  background-color: ${$lightGray4 + "DD"};
  backdrop-filter: none;
`;

const CartCurrentTopbar: React.FC<CartTopbarProps> = props => {
  const { t } = useTranslation();
  const dispatch = props.dispatch;
  const [isNameSelectOpen, setNameSelectOpen] = useState(false);
  useEffect(() => {
    if (!props.orderInProgress && isNameSelectOpen) {
      setNameSelectOpen(false);
    }
    // eslint-disable-next-line
  }, [props.orderInProgress]);
  useEffect(() => {
    if (isNameSelectOpen) {
      dispatch(setCartMinimizedAction(false));
      // reload availability
      if (isNameSelectOpen) {
        dispatch(reloadAvailability());
      }
    }
  }, [isNameSelectOpen, dispatch]);
  const [name, setName] = useState(props.order ? props.order.name : "");
  const [remark, setRemark] = useState(props.order ? props.order.remark : "");
  return (
    <>
      <StyledButtonGroup style={{ float: "left" }}>
        <StyledTopBarButton
          intent={"primary"}
          onClick={() =>
            props.onToggleMinimized &&
            props.onToggleMinimized(!props.isMinimized)
          }
          icon={props.isMinimized ? "maximize" : "minimize"}
        >
          {t("CART")}
        </StyledTopBarButton>
      </StyledButtonGroup>
      <StyledRightButtonGroup>
        <Button
          onClick={() => setNameSelectOpen(true)}
          disabled={props.isCartEmpty}
          fill
          intent="success"
        >
          {t("ORDER")}
        </Button>
        <Dialog
          title={t("CONFIRM_ORDER_TEXT")}
          isOpen={isNameSelectOpen}
          isCloseButtonShown={!props.orderInProgress}
          onClose={() => {
            if (!props.orderInProgress) {
              setNameSelectOpen(false);
            }
          }}
        >
          <div className={DIALOG_BODY} style={{ position: "relative" }}>
            <Label>
              {t("NAME_OF_ORDER")}
              <InputGroup
                value={name}
                disabled={props.orderInProgress}
                onChange={e => setName(e.currentTarget.value)}
                placeholder={t("NAME")}
              />
            </Label>
            <Label>
              {t("ORDER_REMARK")}
              <InputGroup
                 value={remark}
                 disabled={props.orderInProgress}
                 onChange={e => setRemark(e.currentTarget.value.length > 255 ? e.currentTarget.value.substr(0, 255) : e.currentTarget.value)}
                 placeholder={t("ORDER_REMARK")}
              />
            </Label>
            {props.orderInProgress && (
              <StyledDialogOverlay>
                <Spinner />
              </StyledDialogOverlay>
            )}
          </div>
          <div className={DIALOG_FOOTER}>
            <div className={DIALOG_FOOTER_ACTIONS}>
              <Button
                disabled={props.orderInProgress}
                onClick={() => setNameSelectOpen(false)}
              >
                {t("CANCEL")}
              </Button>
              <Button
                disabled={name.length === 0 || props.orderInProgress}
                onClick={() => {
                  props.dispatch(placeOrder(name, remark));
                  // the dialog will be closed when the placeOrder call completes
                }}
                intent="success"
              >
                {t("ORDER")}
              </Button>
            </div>
          </div>
        </Dialog>
      </StyledRightButtonGroup>
    </>
  );
};

const CartTopbar: React.FC<CartTopbarProps> = props => {
  return (
    <StyledNavbar>
      <div>
        {props.mode === CartMode.shop && <CartCurrentTopbar {...props} />}
        {(props.mode === CartMode.orders ||
          props.mode === CartMode.ordersEdit) && (
          <CartOrderPreviewTopbar {...props} />
        )}
      </div>
    </StyledNavbar>
  );
};

export default connect(mapStateToProps)(CartTopbar);
