import { $ } from "./api";

export const setupProductCatalogueSerice = window =>
  (function(window) {
    // if you do not like "sipShopCoreServicesProductCatalogueProxy" as a name
    // change, then change the $package parameter for RPC::serveClass()
    return {
      server: "",
      endPoint:
        "/foomo/modules/SipShop.Core/services/productCatalogue.php/Foomo.Services.RPC/serve",
      operations: {
        /**
         * Returns the allowed values of the custom decor given the passed
         * material id
         *
         * @param string matId the id of the material whose allowed custom decors
         *     should be determined
         *
         * @return SipShop\Core\Services\Vo\CustomDecor value object containing
         *     the allowed values for the custom decor
         */
        getCustomDecorsByMatId: function(matId) {
          return new this._getCustomDecorsByMatId(matId);
        },
        _getCustomDecorsByMatId: function(matId) {
          this.data = {
            endPoint: window.sipShopCoreServicesProductCatalogueProxy.endPoint,
            arguments: { matId: matId },
            complete: false,
            pending: false,
            result: null,
            exception: null,
            errors: [],
            messages: [],
            ajax: null
          };
          this.execute = function(successCallback) {
            this.successCallback = successCallback;
            var me = this;
            this.data.ajax = $.ajax({
              dataType: "JSON",
              url:
                window.sipShopCoreServicesProductCatalogueProxy.server +
                this.data.endPoint +
                "/getCustomDecorsByMatId" +
                "/" +
                escape(this.data.arguments.matId),
              success: function(data) {
                me.data.result = data.value;
                me.data.exception = data.exception;
                me.data.messages = data.messages;
                if (me.data.exception) {
                  me._handleError();
                } else {
                  me.data.success = true;
                  me.successCallback(me);
                }
              },
              error: function(data) {
                me._handleError();
              }
            });
            return this;
          };
          this._handleError = function() {
            this.data.success = false;
            if (this.errorCallback) {
              this.errorCallback(this);
            }
          };
          this.error = function(errorCallback) {
            this.errorCallback = errorCallback;
            return this;
          };
          return this;
        },
        /**
         * Returns localized informations of the product with the id passed
         *
         * @param integer id identifier of the accessory or product
         *
         * @return SipShop\Core\Services\Vo\Product
         */
        getProductById: function(id) {
          return new this._getProductById(id);
        },
        _getProductById: function(id) {
          this.data = {
            endPoint: window.sipShopCoreServicesProductCatalogueProxy.endPoint,
            arguments: { id: id },
            complete: false,
            pending: false,
            result: null,
            exception: null,
            errors: [],
            messages: [],
            ajax: null
          };
          this.execute = function(successCallback) {
            this.successCallback = successCallback;
            var me = this;
            this.data.ajax = $.ajax({
              dataType: "JSON",
              url:
                window.sipShopCoreServicesProductCatalogueProxy.server +
                this.data.endPoint +
                "/getProductById" +
                "/" +
                escape(this.data.arguments.id),
              success: function(data) {
                me.data.result = data.value;
                me.data.exception = data.exception;
                me.data.messages = data.messages;
                if (me.data.exception) {
                  me._handleError();
                } else {
                  me.data.success = true;
                  me.successCallback(me);
                }
              },
              error: function(data) {
                me._handleError();
              }
            });
            return this;
          };
          this._handleError = function() {
            this.data.success = false;
            if (this.errorCallback) {
              this.errorCallback(this);
            }
          };
          this.error = function(errorCallback) {
            this.errorCallback = errorCallback;
            return this;
          };
          return this;
        },
        /**
         * Returns localized informations of the product group and its associated
         * products and accessories
         *
         * @param integer id identifier of the product group
         *
         * @return SipShop\Core\Services\Vo\ProductGroup
         */
        getProductGroupById: function(id) {
          return new this._getProductGroupById(id);
        },
        _getProductGroupById: function(id) {
          this.data = {
            endPoint: window.sipShopCoreServicesProductCatalogueProxy.endPoint,
            arguments: { id: id },
            complete: false,
            pending: false,
            result: null,
            exception: null,
            errors: [],
            messages: [],
            ajax: null
          };
          this.execute = function(successCallback) {
            this.successCallback = successCallback;
            var me = this;
            this.data.ajax = $.ajax({
              dataType: "JSON",
              url:
                window.sipShopCoreServicesProductCatalogueProxy.server +
                this.data.endPoint +
                "/getProductGroupById" +
                "/" +
                escape(this.data.arguments.id),
              success: function(data) {
                me.data.result = data.value;
                me.data.exception = data.exception;
                me.data.messages = data.messages;
                if (me.data.exception) {
                  me._handleError();
                } else {
                  me.data.success = true;
                  me.successCallback(data);
                }
              },
              error: function(data) {
                me._handleError();
              }
            });
            return this;
          };
          this._handleError = function() {
            this.data.success = false;
            if (this.errorCallback) {
              this.errorCallback(this);
            }
          };
          this.error = function(errorCallback) {
            this.errorCallback = errorCallback;
            return this;
          };
          return this;
        },
        /**
         * Returns localized series the user who is logged in can see
         *
         *
         * @return SipShop\Core\Services\Vo\LabeledData[]
         */
        getSeries: function() {
          return new this._getSeries();
        },
        _getSeries: function() {
          this.data = {
            endPoint: window.sipShopCoreServicesProductCatalogueProxy.endPoint,
            arguments: {},
            complete: false,
            pending: false,
            result: null,
            exception: null,
            errors: [],
            messages: [],
            ajax: null
          };
          this.execute = function(successCallback) {
            this.successCallback = successCallback;
            var me = this;
            this.data.ajax = $.ajax({
              dataType: "JSON",
              url:
                window.sipShopCoreServicesProductCatalogueProxy.server +
                this.data.endPoint +
                "/getSeries",
              success: function(data) {
                me.data.result = data.value;
                me.data.exception = data.exception;
                me.data.messages = data.messages;
                if (me.data.exception) {
                  me._handleError();
                } else {
                  me.data.success = true;
                  me.successCallback(me);
                }
              },
              error: function(data) {
                me._handleError();
              }
            });
            return this;
          };
          this._handleError = function() {
            this.data.success = false;
            if (this.errorCallback) {
              this.errorCallback(this);
            }
          };
          this.error = function(errorCallback) {
            this.errorCallback = errorCallback;
            return this;
          };
          return this;
        },
        /**
         * Returns the product tree or the flat list of search results
         *
         * @param SipShop\Core\Services\Vo\SearchRequest request search request
         *
         * @return SipShop\Core\Services\Vo\SearchResult $ret result of the search
         */
        search: function(request) {
          return new this._search(request);
        },
        _search: function(request) {
          this.data = {
            endPoint: window.sipShopCoreServicesProductCatalogueProxy.endPoint,
            arguments: { request: request },
            complete: false,
            pending: false,
            result: null,
            exception: null,
            errors: [],
            messages: [],
            ajax: null
          };
          this.execute = function(successCallback) {
            this.successCallback = successCallback;
            var me = this;
            this.data.ajax = $.ajax({
              dataType: "JSON",
              url:
                window.sipShopCoreServicesProductCatalogueProxy.server +
                this.data.endPoint +
                "/search",
              data: { request: this.data.arguments.request },
              type: "POST",
              success: function(data) {
                me.data.result = data.value;
                me.data.exception = data.exception;
                me.data.messages = data.messages;
                if (me.data.exception) {
                  me._handleError();
                } else {
                  me.data.success = true;
                  me.successCallback(me);
                }
              },
              error: function(data) {
                me._handleError();
              }
            });
            return this;
          };
          this._handleError = function() {
            this.data.success = false;
            if (this.errorCallback) {
              this.errorCallback(this);
            }
          };
          this.error = function(errorCallback) {
            this.errorCallback = errorCallback;
            return this;
          };
          return this;
        },
        /**
         * Returns an array of product VOs that have a matId beginning with or are
         * identical to the passed $matIdSearched
         *
         * @param string matIdSearched the sought products have to have a matId
         *     beginning with or equal to this string
         *
         * @return SipShop\Core\Services\Vo\Product[] array of products that fit
         *     the search criterion
         */
        searchProductsByMatId: function(matIdSearched) {
          return new this._searchProductsByMatId(matIdSearched);
        },
        _searchProductsByMatId: function(matIdSearched) {
          this.data = {
            endPoint: window.sipShopCoreServicesProductCatalogueProxy.endPoint,
            arguments: { matIdSearched: matIdSearched },
            complete: false,
            pending: false,
            result: null,
            exception: null,
            errors: [],
            messages: [],
            ajax: null
          };
          this.execute = function(successCallback) {
            this.successCallback = successCallback;
            var me = this;
            this.data.ajax = $.ajax({
              dataType: "JSON",
              url:
                window.sipShopCoreServicesProductCatalogueProxy.server +
                this.data.endPoint +
                "/searchProductsByMatId" +
                "/" +
                escape(this.data.arguments.matIdSearched),
              success: function(data) {
                me.data.result = data.value;
                me.data.exception = data.exception;
                me.data.messages = data.messages;
                if (me.data.exception) {
                  me._handleError();
                } else {
                  me.data.success = true;
                  me.successCallback(me);
                }
              },
              error: function(data) {
                me._handleError();
              }
            });
            return this;
          };
          this._handleError = function() {
            this.data.success = false;
            if (this.errorCallback) {
              this.errorCallback(this);
            }
          };
          this.error = function(errorCallback) {
            this.errorCallback = errorCallback;
            return this;
          };
          return this;
        }
      }
    };
  })(window);
