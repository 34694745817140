import {SipShopCoreServicesVoCustomDecor, SipShopCoreServicesVoLabeledData, SipShopCoreServicesVoProduct, SipShopCoreServicesVoProductGroup, SipShopCoreServicesVoTreeEntry,} from '../../services/productCatalogue';
import {actionCreator} from '../store';

export const SET_SERIES = 'SET_SERIES';
export const SET_SELECTION = 'SET_SELECTION';
export const SET_SELECTED_PATH = 'SET_SELECTED_PATH';
export const SET_PRODUCT_TREE = 'SET_PRODUCT_TREE';
export const SET_SELECTED_SERIES = 'SELECT_SERIES';
export const SET_CUSTOM_DECOR = 'SET_CUSTOM_DECOR';
export const SET_CUSTOM_DECOR_SELECTION = 'SET_CUSTOM_DECOR_SELECTION';
export const SET_CUSTOM_PRODUCT_SELECTION = 'SET_CUSTOM_PRODUCT_SELECTION';
export const SET_CUSTOM_MULTI_COLOR = 'SET_CUSTOM_MULTI_COLOR';

export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
export type Subtract<T, K> = Omit<T, keyof K>;


export enum DecorType {
  inner = 'inner',
  outer = 'outer',
  both = 'both'
}

export enum MultiColor {
    uni = 'uni',
    biColor = 'biColor'
}

export interface CustomDecorState extends SipShopCoreServicesVoCustomDecor {
  series: string;
  selectedDecor: number|null;
  selectedCustomDecor: number|null;
  productId: string|null;
  multiColor: MultiColor|undefined;
  isComplete?: boolean;
}

export type ProductSelectionState =|undefined|null|{
  item?: SipShopCoreServicesVoProduct;
  group: SipShopCoreServicesVoProductGroup;
  loading?: boolean;
};

/**
 * State type
 */
export interface ShopState {
  // null means loading state
  productTree?: SipShopCoreServicesVoTreeEntry[]|null;
  // list of all available series for the user
  seriesList: SipShopCoreServicesVoLabeledData[]|null;

  customDecor: CustomDecorState|null;
  selection: ProductSelectionState;
  selectionPath: number[]|null;
  seriesSelected?: string;
}

/**
 *
 * Action creators
 *
 */

export const setSeriesAction =
    (series: SipShopCoreServicesVoLabeledData[]|null) =>
        actionCreator(SET_SERIES, series);

export const setSelectionAction = (selection: ProductSelectionState) =>
    actionCreator(SET_SELECTION, selection);


export const setProductTreeAction =
    (series: SipShopCoreServicesVoTreeEntry[]|null|undefined) =>
        actionCreator(SET_PRODUCT_TREE, series);

export const setSelectedSeriesAction =
    (selection: SipShopCoreServicesVoLabeledData|null) =>
        actionCreator(SET_SELECTED_SERIES, selection);

export const setCustomDecorAction = (decor: CustomDecorState|null) =>
    actionCreator(SET_CUSTOM_DECOR, decor);

export const setCustomDecorSelectionAction = (selectedDecor: number|null) =>
    actionCreator(SET_CUSTOM_DECOR_SELECTION, {selectedDecor});

export const setCustomMultiColorAction = (multiColor: MultiColor|undefined) =>
    actionCreator(SET_CUSTOM_MULTI_COLOR, {multiColor});

export const setCustomDecorProductSelectionAction = (selectedCustomDecor: number|null) =>
    actionCreator(SET_CUSTOM_PRODUCT_SELECTION, {selectedCustomDecor});

export const setSelectionPathAction = (path: number[]|null) =>
    actionCreator(SET_SELECTED_PATH, path);

/**
 * Initial state
 */
const initialState: ShopState = {
  productTree: undefined,
  // null indicates loading
  seriesList: null,
  // series selection for loading input

  customDecor: null,
  selection: undefined,
  selectionPath: null
};

/**
 * Reducer
 * @param state
 * @param action
 */
const shopReducer =
    (state: ShopState = initialState,
     action: ReturnType<
         |typeof setSeriesAction|typeof setSelectedSeriesAction|
         typeof setProductTreeAction|typeof setSelectionAction|
         typeof setCustomDecorAction|typeof setCustomDecorSelectionAction|
         typeof setCustomMultiColorAction|typeof setCustomDecorProductSelectionAction|
         typeof setSelectionPathAction>):
        ShopState => {
          switch (action.type) {
            case SET_SELECTED_PATH:
              return {...state, selectionPath: action.payload};
            case SET_SELECTION:
              // reset decor if switching selection
              return {...state, selection: action.payload, customDecor: null};
            case SET_PRODUCT_TREE:
              return {...state, productTree: action.payload};

            case SET_SERIES:
              // if we have a selection try to find it in the new
              // data
              // if (state.selection && action.payload) {
              //   const selection = action.payload.find(
              //       item => item.data === state.selection.group.);
              //   search.seriesSelected = selection ? selection.data :
              //   undefined; return {...state};
              // }

              return {...state, seriesList: action.payload};
            case SET_SELECTED_SERIES:
              return {
                ...state,
                seriesSelected: action.payload ? action.payload.data : undefined
              };
            case SET_CUSTOM_DECOR:
              return {...state, customDecor: action.payload};
            case SET_CUSTOM_MULTI_COLOR:
                return {
                    ...state,

                    customDecor: state.customDecor ? {
                      ...state.customDecor,
                      ...action.payload,
                      selectedDecor: null,
                      selectedCustomDecor: null,
                      productId: '',
                      isComplete: false
                    } :

                    null
                };

            case SET_CUSTOM_DECOR_SELECTION:
              return {
                ...state,

                customDecor: state.customDecor ? {
                  ...state.customDecor,
                  ...action.payload,
                  selectedCustomDecor: null,
                  productId: '',
                  isComplete: false
                } :

                null
              };

            case SET_CUSTOM_PRODUCT_SELECTION:
              const newState = {
                ...state,
                customDecor: state.customDecor ?
                    {...state.customDecor, ...action.payload} :
                    null
              };

              return updateProductId(newState);
          }
          return state;
        };

const updateProductId = (state: ShopState): ShopState => {
  if (state.customDecor === null ||
      state.customDecor.selectedDecor === null ||
      state.customDecor.selectedCustomDecor === null) {
    return state;
  }
  let mc = state.customDecor.allowsBiColor && typeof state.customDecor.multiColor !== 'undefined' ? state.customDecor.multiColor : MultiColor.uni;
  if (mc === MultiColor.uni) {
      state.customDecor.productId = state.customDecor.uniColoredDecors[state.customDecor.selectedDecor].decors[state.customDecor.selectedCustomDecor].matId;
  } else {
      state.customDecor.productId = state.customDecor.biColoredDecors[state.customDecor.selectedDecor].decors[state.customDecor.selectedCustomDecor].matId;
  }

  state.customDecor.isComplete = true;
  return state;
};

export default shopReducer;
