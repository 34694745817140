import {
  Button,
  Classes,
  Dialog,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Tooltip
} from "@blueprintjs/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import styled from "styled-components";

import { ReduxRootType } from "../store/store";
import { logout } from "../store/actions/user";
import ResetPassword from "./ResetPassword";

const StyledUserMenuBlock = styled.div`
  padding: 10px;
  h4 {
    margin: 0;
  }
`;

const StyledUserButton = styled(Button)`
  .bp3-button-text {
    max-width: 120px;
    @media screen and (max-width: 1024px) {
      display: none;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface UserMenuDropdownProps extends UserMenuProps {
  onResetPassword: () => void;
}

const UserMenuDropdown: React.FC<UserMenuDropdownProps> = props => {
  const { t } = useTranslation();
  return (
    <>
      <Menu>
        <StyledUserMenuBlock>
          <p>{t("LOGGED_AS")}</p>
          <h4>{props.companyName}</h4>
        </StyledUserMenuBlock>
        <StyledUserMenuBlock>
          <p>{t("EMAIL")}</p>
          <h4>{props.userEmail}</h4>
        </StyledUserMenuBlock>
        <MenuDivider />
        <MenuItem
          onClick={props.onResetPassword}
          icon="key"
          text={t("PASSWORD_CHANGE")}
        />
        <MenuDivider />
        <MenuItem
          icon="log-out"
          onClick={() => props.dispatch(logout())}
          text={t("DISCONNECT")}
        />
      </Menu>
    </>
  );
};

interface UserMenuProps {
  companyName: string;
  userEmail: string;
  dispatch: ThunkDispatch<{}, {}, any>;
}

const UserMenu: React.FC<UserMenuProps> = props => {
  const { t } = useTranslation();
  const [isResetPasswordOpen, setResetPasswordOpen] = useState(false);
  return (
    <>
      <Popover>
        <Tooltip content={props.companyName}>
          <StyledUserButton
            icon="user"
            rightIcon="caret-down"
            className={Classes.MINIMAL}
            text={props.companyName}
          />
        </Tooltip>
        <UserMenuDropdown
          {...props}
          onResetPassword={() => setResetPasswordOpen(true)}
        />
      </Popover>
      <Dialog
        icon="key"
        title={t("PASSWORD_CHANGE")}
        isOpen={isResetPasswordOpen}
        onClose={() => setResetPasswordOpen(false)}
      >
        <ResetPassword onCancel={() => setResetPasswordOpen(false)} />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: ReduxRootType) => ({
  userEmail: state.user !== null ? state.user.email : "",
  companyName: state.user !== null ? state.user.companyName : ""
});

export default connect(mapStateToProps)(UserMenu);
