import {IconName, Intent} from '@blueprintjs/core';

import {Message} from '../../services/productCatalogue';
import {actionCreator} from '../store';

const SET_MESSAGES = 'SET_MESSAGES';
const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
const ADD_MESSAGES = 'ADD_MESSAGES';
const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

type MessageType =
    Message&{id?: string, localize?: boolean, icon?: IconName, intent?: Intent};
export type MessageState = MessageType[]|null;

let messageId = 0;

export const actionAddMessages = (msgs: MessageType[]) =>
    actionCreator(ADD_MESSAGES, msgs);
export const actionSetMessages = (msgs: MessageType[]|null) =>
    actionCreator(SET_MESSAGES, msgs);
export const actionClearMessages = () => actionCreator(CLEAR_MESSAGES, {});
export const actionClearMessage = (msgId: string) =>
    actionCreator(CLEAR_MESSAGE, msgId);

type ActionTypes =
    ReturnType<|typeof actionAddMessages|typeof actionSetMessages|
               typeof actionClearMessages|typeof actionClearMessage>;

const messagesReducer =
    (state: MessageState = null, action: ActionTypes): MessageState => {
      switch (action.type) {
        case SET_MESSAGES:
          return action.payload;
        case CLEAR_MESSAGES:
          return null;
        case ADD_MESSAGES:
          // check that all messages have a unique id
          action.payload.filter(m => m).forEach(m => {
            if (!m.id) {
              m.id = messageId + '' + new Date().getTime();
              messageId++;
            }
          });
          return state ? [...action.payload, ...state] : action.payload;
        case CLEAR_MESSAGE:
          return state ? state.filter(msg => msg.id === action.payload) : state;
        default:
          return state;
      }
    };

export default messagesReducer;
