import {
  CurrentCart,
  SipShopCoreServicesVoCartEnv,
  SipShopCoreServicesVoProduct
} from "../../services/productCatalogue";
import { reorder } from "../../utils";
import { appendCartItem } from "../actions/cart";
import { actionCreator } from "../store";

export const SET_CART = "SET_CART";
export const SET_LOADING_AVAILABILITY = "SET_LOADING_AVAILABILITY";
export const SET_ORDER_IN_PROGRESS = "SET_ORDER_IN_PROGRESS";
export const SET_CART_ENV = "SET_CART_ENV";
export const SET_CART_KEY = "SET_CART_KEY";
export const SET_CART_ORDER = "SET_CART_ORDER";

export const EDIT_CART_ITEM_QTY = "EDIT_CART_ITEM_QTY";
export const UNDERCUT_CART_ITEM_QTY = "UNDERCUT_CART_ITEM_QTY";
export const SET_QUICK_INSERT_QUERY = "SET_QUICK_INSERT_QUERY";
export const SET_QUICK_INSERT_RESULTS = "SET_QUICK_INSERT_RESULTS";
export const SET_QUICK_INSERT_ERROR = "SET_QUICK_INSERT_ERROR";

export interface QuickInsertState {
  error: string | undefined;
  query: string;
  // null = no-results;
  // undefined = loading;
  products: SipShopCoreServicesVoProduct[] | undefined | null;
}

/**
 * State type
 */
export interface CartState {
  // set when a order request is sent
  orderInProcess: boolean;
  // true when waiting for availability data
  loadingAvailability?: boolean;
  // null means loading state
  current:
    | (CurrentCart & {
        editItemIndex?: number;
        editItemQty?: number;
        editItemDialog?: boolean;
      })
    | null; // SipShopCoreServicesVoTreeEntry[] | null;
  env: SipShopCoreServicesVoCartEnv | null;
  quickInsert: QuickInsertState;
}

export enum DecorType {
  BaseColor = 0,
  InsideColor,
  OutsideColor,
  BothsidesColorSimilar,
  BothsidesColor,
  NoDecor
}

/**
 * Action creators
 */

export const setLoadingAvailabilityAction = (loading: boolean) =>
  actionCreator(SET_LOADING_AVAILABILITY, loading);

export const setOrderInProgressAction = (loading: boolean) =>
  actionCreator(SET_ORDER_IN_PROGRESS, loading);

export const setCartAction = (cart: CurrentCart | null) =>
  actionCreator(SET_CART, cart);

export const setCartEnvAction = (env: SipShopCoreServicesVoCartEnv | null) =>
  actionCreator(SET_CART_ENV, env);

export const setCartKeyAction = (key: keyof CurrentCart, value: any) =>
  actionCreator(SET_CART_KEY, { key, value });

export const setCartOrder = (source: number, destination: number) =>
  actionCreator(SET_CART_ORDER, { source, destination });

export const setQuickInsertQuery = (query: string) =>
  actionCreator(SET_QUICK_INSERT_QUERY, query);

export const setQuickInsertError = (err: string | undefined) =>
  actionCreator(SET_QUICK_INSERT_ERROR, err);

export const setQuickInsertProducts = (
  products: SipShopCoreServicesVoProduct[] | null | undefined
) => actionCreator(SET_QUICK_INSERT_RESULTS, products);
/**
 * set or remove edit field from edit cart
 * @param index of item in cart
 */
export const editCartItemQty = (index: number | undefined) =>
  actionCreator(EDIT_CART_ITEM_QTY, index);

export const undercutCartItemQty = (qty: number | undefined) =>
  actionCreator(UNDERCUT_CART_ITEM_QTY, qty);

/**
 * Initial state
 */
const initialState = {
  current: null,
  env: null,
  orderInProcess: false,
  quickInsert: { query: "", error: undefined, products: null }
};

type CartActionType = ReturnType<
  | typeof setCartAction
  | typeof setCartEnvAction
  | typeof setCartKeyAction
  | typeof setCartOrder
  | typeof editCartItemQty
  | typeof setQuickInsertQuery
  | typeof setLoadingAvailabilityAction
  | typeof setQuickInsertProducts
  | typeof setQuickInsertError
  | typeof setOrderInProgressAction
  | typeof undercutCartItemQty
>;

const cartReducer = (
  state: CartState = initialState,
  action: CartActionType
): CartState => {
  switch (action.type) {
    case SET_ORDER_IN_PROGRESS:
      return {
        ...state,
        orderInProcess: action.payload
      };
    case SET_LOADING_AVAILABILITY:
      return { ...state, loadingAvailability: action.payload };
    case SET_QUICK_INSERT_ERROR:
      return {
        ...state,
        quickInsert: { ...state.quickInsert, error: action.payload }
      };
    case SET_QUICK_INSERT_QUERY:
      return {
        ...state,
        quickInsert: {
          error: undefined,
          products: undefined,
          query: action.payload
        }
      };
    case SET_QUICK_INSERT_RESULTS:
      return {
        ...state,
        quickInsert: { ...state.quickInsert, products: action.payload }
      };
    case UNDERCUT_CART_ITEM_QTY:
      return {
        ...state,
        current: state.current
          ? { ...state.current, editItemQty: action.payload }
          : state.current
      };
    case EDIT_CART_ITEM_QTY:
      return {
        ...state,
        current: state.current
          ? { ...state.current, editItemIndex: action.payload }
          : state.current
      };
    case SET_CART_KEY:
      if (!state.current) {
        return state;
      }
      let cart = { ...state.current };
      (cart[action.payload.key] as any) = action.payload.value;
      return { ...state, current: cart };
    case SET_CART_ENV:
      return { ...state, env: action.payload };
    case SET_CART_ORDER:
      if (!state.current) {
        return state;
      }

      // update current cart order
      return {
        ...state,
        current: {
          ...state.current,
          items: appendCartItem(
            reorder(
              state.current.items,
              action.payload.source,
              action.payload.destination
            )
          )
        }
      };
    case SET_CART:
      return { ...state, current: action.payload };
  }
  return state;
};

export default cartReducer;
