import {
  HTMLInputProps,
  IInputGroupProps,
  InputGroup
} from "@blueprintjs/core";
import React, { useCallback, useEffect, useState } from "react";

interface ClickToEditFieldProps {
  value: string;
  inputProps?: IInputGroupProps & HTMLInputProps;
  onEdit?: () => void;
  onEditEnd?: (value: string) => void;
  onEditCancel?: (value: string) => void;
  isEditing?: boolean;
  noAutofocus?: boolean;
}

const ClickToEditField: React.FC<ClickToEditFieldProps> = ({
  value,
  inputProps,
  onEdit,
  onEditEnd,
  onEditCancel,
  isEditing,
  noAutofocus
}) => {
  /**
   * state
   */
  const [internalVal, setValue] = useState(value);
  const [inputRef, setInputRef] = useState<null | HTMLInputElement>(null);

  // handle input field click
  const clickHandler = useCallback(() => {
    console.debug("inputRed:", inputRef);
    if (!isEditing && onEdit) {
      onEdit();
    }
  }, [onEdit, isEditing, inputRef]);

  // handle esc & enter key presses
  const keyHander = useCallback(
    (evt: React.KeyboardEvent<HTMLInputElement>) => {
      // blur on esc
      if (evt.keyCode === 27 || evt.key === "Escape") {
        if (onEditCancel) {
          onEditCancel(internalVal);
        }
        return;
      }

      if (inputRef && (evt.key === "Enter" || evt.keyCode === 13)) {
        inputRef.blur();
        return;
      }
    },
    [onEditCancel, inputRef, internalVal]
  );
  // focus input field and add event handlers
  useEffect(() => {
    if (inputRef && !noAutofocus) {
      inputRef.focus();
    }
  }, [inputRef, keyHander, noAutofocus]);

  // update internal value on props update
  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <div onClick={clickHandler}>
      {isEditing ? (
        <InputGroup
          inputRef={el => setInputRef(el)}
          {...(inputProps ? inputProps : {})}
          value={internalVal}
          onChange={e => setValue(e.target.value)}
          onKeyPress={keyHander}
          onBlur={() => onEditEnd && onEditEnd(internalVal)}
        />
      ) : (
        value
      )}
    </div>
  );
};

export default ClickToEditField;
