// import {ProductCatalogueService} from './productCatalogue';
import { ThunkDispatch } from "redux-thunk";

import { logout } from "../store/actions/user";
import {
  clearErrorAction,
  ErrorType,
  setErrorAction
} from "../store/reducers/error";
import { actionAddMessages } from "../store/reducers/messages";
import { addErrorToast } from "../store/reducers/toasts";
import { setupCartService } from "./cartService";
import { setupOrderService } from "./orderService";
import { setupProductCatalogueSerice } from "./productCatalogueService";
import { setupSessionService } from "./sessionService";

// utility for performing async thunk requests
// this will
// - clear and set error if provided
// - execute a call on the API
export const asyncActionWrapper = async <
  T extends {
    _handleError?: any;
    error?: (err: any) => void;
    execute: (success: any) => void;
  }
>(
  operation: T,
  dispatch: ThunkDispatch<{}, {}, any>,
  errorType?: ErrorType,
  silent: boolean | undefined = undefined,
  failOnMessage: boolean = false
) => {
  // reset error
  if (errorType) {
    dispatch(clearErrorAction(errorType));
  }

  return await new Promise((resolve, reject) => {
    if (operation.error) {
      operation.error(err => {
        console.error(err);
        // check for network and other basic errors
        // since they won't have API keys on them
        if (!err.data) {
          dispatch(setErrorAction(ErrorType.unknown, err));
          // set auth error
        } else if (
          err.data.exception && typeof err.data.exception.msg !== 'undefined' &&
          err.data.exception.msg.indexOf("Zugriff verweigert") !== -1
        ) {
          // check for auth error
          // FIXME: use http status code or code prefixed in the message
          // logout user
          dispatch(logout());

          // set auth error
          dispatch(setErrorAction(ErrorType.access, err.data.exception));
        } else if (err.data.exception && typeof err.data.exception.msg !== 'undefined' && err.data.exception.msg && !silent) {
          dispatch(addErrorToast(err.data.exception.msg));
        } else if (err.data.exception && typeof err.data.exception.message !== 'undefined' && err.data.exception.message && !silent) {
            dispatch(addErrorToast(err.data.exception.message));
        }
        // set error
        if (errorType) {
          dispatch(setErrorAction(errorType, err.data.exception));
        }

        return reject(err.data.exception);
      });
    }

    operation.execute(data => {
      if (data && data.data && data.data.result) {
        var hasFailed = false;
        if (
          !silent &&
          data.data.messages &&
          data.data.messages.length &&
          data.data.messages.length > 0
        ) {
          dispatch(actionAddMessages(data.data.messages));
          // check if the messages contain a error
          data.data.messages.forEach((msg: { errorLevel?: number }) => {
            if (failOnMessage && msg.errorLevel && msg.errorLevel > 0) {
              hasFailed = true;
            }
          });
        }

        if (hasFailed) {
          reject(data.data.messages);
          return;
        }

        resolve(data.data.result);
        return;
      } else if (data.value) {
        if (data.messages && data.messages.length && data.messages.length > 0) {
          dispatch(actionAddMessages(data.messages));
        }
        resolve(data.value);
      } else {
        console.warn(
          "unknown data result wrapper: expected {id: ..., data: got",
          data
        );
        resolve(data.data);
        return;
      }
    });
  });
};

// fake jQuery
export const $ = {
  ajax: options =>
    fetch(options.url, {
      method: options.type,
      // this is still needed for older browsers since
      // a 'same-origin' became default in 2019
      credentials: "same-origin",
      headers: { "Content-Type": "application/json; charset=UTF-8" },
      body: options.data
        ? typeof options.data === "object"
          ? JSON.stringify(options.data)
          : options.data
        : undefined
    })
      .then(data => data.json())
      .then(data => options.success(data))
      .catch(err => options.error(err))
};

enum Service {
  Catalogue = "sipShopCoreServicesProductCatalogueProxy",
  Session = "sipShopCoreServicesSessionServiceProxy",
  Cart = "sipShopCoreServicesCartProxy",
  Order = "sipShopCoreServicesOrderProxy"
}

export const getSessionService = (): ReturnType<typeof setupSessionService> => {
  return (window as any)[Service.Session];
};
export const getCatalogueService = (): ReturnType<
  typeof setupProductCatalogueSerice
> => {
  return (window as any)[Service.Catalogue];
};
export const getCartService = (): ReturnType<typeof setupCartService> => {
  return (window as any)[Service.Cart];
};
export const getOrderService = (): ReturnType<typeof setupOrderService> => {
  return (window as any)[Service.Order];
};

export const initServices = () => {
  window[Service.Catalogue] = setupProductCatalogueSerice(window);
  window[Service.Session] = setupSessionService(window);
  window[Service.Cart] = setupCartService(window);
  window[Service.Order] = setupOrderService(window);
};
