import "react-virtualized/styles.css";

import { H4, Icon, Spinner, Tooltip } from "@blueprintjs/core";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  AutoSizer,
  Column,
  defaultTableRowRenderer,
  Table,
  TableCellRenderer,
  TableHeaderRenderer,
  TableRowRenderer
} from "react-virtualized";
import styled from "styled-components";

import orderEdited from "../assets/images/orderEdited.png";
import orderOK from "../assets/images/orderOK.png";
import orderOnTime from "../assets/images/orderOnTime.png";
import orderPen from "../assets/images/orderPen.png";
import { localeStringConverter } from "../i18n";
import { SipShopCoreServicesVoOrder } from "../services/productCatalogue";
import { ReduxRootType } from "../store/store";
import {
  $green2,
  $lightGray1,
  $lightGray3,
  $lightGray4,
  $lightGray5
} from "../theme";
import { ConnectedComponent, defaultTimeFormat } from "../utils";

const StyledTable = styled(Table)`
  padding-left: 20px;
  font-size: 12px;
  .ReactVirtualized__Table__headerRow {
    background: linear-gradient(${$lightGray4}, ${$lightGray3});
    font-weight: bold;
    /* add this for force constant spacing to content by also adding a scroll bar */
    overflow-y: scroll;
    width: auto;
    border: 1px solid ${$lightGray1};
    text-transform: none;
  }
  .ReactVirtualized__Table__headerColumn {
    border-right: 1px solid ${$lightGray1};

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    box-sizing: border-box;
    &:last-of-type {
      border-right: none;
    }
    line-height: 16px;
    /* margin: 0; */
  }

  .ReactVirtualized__Table__Grid {
    border: 1px solid ${$lightGray1};
    background-color: ${$lightGray3};
  }

  .ReactVirtualized__Table__row {
    border-color: ${$lightGray1};
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    cursor: pointer;

    background-color: #fff;
    &:nth-child(2n) {
      background-color: ${$lightGray5};
    }

    &:hover {
      background-color: ${$lightGray1};
    }

    &.active {
      background-color: ${$green2};
      color: #fff;
    }
  }
`;

const StyledStatusImg = styled.img`
  padding-right: 3px;
`;

const HeaderRowCell = styled.span``;

const mapStateToProps = (state: ReduxRootType) => ({});

interface OrderTableProps extends ConnectedComponent<typeof mapStateToProps> {
  loading?: boolean;
  omitColumns?: (keyof SipShopCoreServicesVoOrder)[];
  orders: SipShopCoreServicesVoOrder[];
  sortBy?: keyof SipShopCoreServicesVoOrder;
  sortDirection?: "ASC" | "DESC";
  onSort: (options: {
    sortBy: keyof SipShopCoreServicesVoOrder;
    sortDirection: "ASC" | "DESC";
  }) => void;
  selectedId?: string;
  widthInset?: number;
  heightInset?: number;
  onOrderSelected?: (order: SipShopCoreServicesVoOrder) => void;
  className?: string;
  tableProps?: Partial<{
    width: number;
    height;
    number;
    headerHeight: number;
    headerClassName: string;
  }>;
}
/**
 * creates a row renderer that handles selected rows
 * this will append active to the selected row
 * @param selectedId
 */
const orderRowRendererCreator = (
  selectedId?: string
): TableRowRenderer => props => {
  return defaultTableRowRenderer(
    (props.rowData as SipShopCoreServicesVoOrder).id === selectedId
      ? {
          ...props,
          className: props.className + " active"
        }
      : props
  );
};

const StyledTableEmptyDiv = styled.div`
  opacity: 0.3;
  padding: 25px;
  text-align: center;
`;

const OrderTable: React.FC<OrderTableProps> = ({
  orders,
  sortBy,
  sortDirection,
  omitColumns = [],
  onOrderSelected,
  onSort,
  className,
  tableProps = {},
  widthInset = 40,
  heightInset = 60,
  selectedId,
  loading
}) => {
  const { t, i18n } = useTranslation();
  const [scrollIndex, setScrollIndex] = useState<number | undefined>();
  // on initial load scroll to selection
  useEffect(() => {
    if (!selectedId) {
      return;
    }
    // find item
    const itemIndex = orders.findIndex(order => order.id === selectedId);
    // scroll to item
    if (itemIndex !== -1) {
      setScrollIndex(itemIndex);
    }
  }, [selectedId, orders]);

  // create a localized date cell render
  const dateRenderer = useCallback(
    dateRendererCreator(localeStringConverter(i18n.language)),
    [i18n.language]
  );
  // create a new row renderer if selectedId changed
  const rowRenderer = useCallback(orderRowRendererCreator(selectedId), [
    selectedId
  ]);

  const onRowSelected = useCallback(
    (params: {
      rowData: SipShopCoreServicesVoOrder;
      index: number;
      e: SyntheticEvent;
    }) => {
      if (!onOrderSelected) {
        return;
      }
      onOrderSelected(params.rowData);
      setScrollIndex(params.index);
    },
    [onOrderSelected]
  );

  const onSortCallback = useCallback(onSort && (options => onSort(options)), [
    onSort
  ]);

  // check if column requires render
  const showColumn = (key: keyof SipShopCoreServicesVoOrder) => {
    return omitColumns.findIndex(col => col === key) === -1;
  };
  return (
    <>
      <AutoSizer>
        {({ width, height }) => (
          <StyledTable
            sort={onSortCallback}
            onRowClick={onRowSelected}
            rowRenderer={rowRenderer}
            sortBy={sortBy}
            noRowsRenderer={() =>
              loading ? (
                <Spinner />
              ) : (
                <StyledTableEmptyDiv>
                  <Icon icon="th-disconnect" iconSize={40} />
                  <H4>{t("NO_ORDERS")}</H4>
                </StyledTableEmptyDiv>
              )
            }
            sortDirection={sortDirection}
            width={width - widthInset}
            height={height - heightInset}
            headerHeight={40}
            rowHeight={34}
            scrollToIndex={scrollIndex}
            scrollToAlignment="auto"
            rowCount={orders.length}
            headerClassName="header"
            rowGetter={({ index }) => orders[index]}
            className={className}
            {...tableProps}
          >
            {showColumn("name") && (
              <Column
                headerRenderer={headerRenderer}
                minWidth={140}
                flexGrow={0.6}
                width={140}
                label={t("NAME")}
                dataKey="name"
              />
            )}

            {showColumn("remark") && (
               <Column
                 headerRenderer={headerRenderer}
                 minWidth={140}
                 flexGrow={0.6}
                 width={140}
                 label={t("ORDER_REMARK")}
                 dataKey="remark"
               />
            )}

            {showColumn("sapOrderId") && (
              <Column
                headerRenderer={headerRenderer}
                width={200}
                flexGrow={0}
                label={t("ORDER_NUMBER")}
                dataKey="sapOrderId"
              />
            )}
            {showColumn("id") && (
              <Column
                headerRenderer={headerRenderer}
                width={200}
                flexGrow={0}
                label={t("ORDER_NR")}
                dataKey="id"
              />
            )}
            {showColumn("statusUpdated") && (
              <Column
                headerRenderer={headerRenderer}
                width={100}
                label={t("DATE")}
                cellRenderer={dateRenderer}
                dataKey="statusUpdated"
              />
            )}
            {showColumn("owner") && (
              <Column
                headerRenderer={headerRenderer}
                width={150}
                label={t("EDITOR")}
                cellRenderer={labeledDataRenderer}
                dataKey="owner"
              />
            )}
            {showColumn("status") && (
              <Column
                headerRenderer={headerRenderer}
                width={120}
                minWidth={120}
                cellRenderer={deliveryStateRenderer}
                label={t("DELIVERY_STATE")}
                dataKey="status"
              />
            )}
          {showColumn("deliveryDate") && (
              <Column
                  headerRenderer={headerRenderer}
                  width={100}
                  label={t("DELIVERY_DATE")}
                  cellRenderer={dateRenderer}
                  dataKey="deliveryDate"
              />
          )}
            {showColumn("deliveryAddress") && (
              <Column
                headerRenderer={headerRenderer}
                cellRenderer={labeledDataRenderer}
                width={120}
                flexGrow={0.5}
                label={t("RECIPIENT_ADDRESS")}
                dataKey="deliveryAddress"
              />
            )}
            {showColumn("cntDocuments") && (
              <Column
                headerRenderer={headerRenderer}
                width={40}
                label={t("DOCUMENTS")}
                dataKey="cntDocuments"
              />
            )}
          </StyledTable>
        )}
      </AutoSizer>
    </>
  );
};

export default connect(mapStateToProps)(OrderTable);

/**
 * Header cell renderer
 * @param props
 */
const headerRenderer: TableHeaderRenderer = props => (
  <Tooltip content={<>{props.label}</>}>
    <>
      <HeaderRowCell
        style={
          props.dataKey === props.sortBy ? { color: "#0A6640" } : undefined
        }
      >
        {props.label}
      </HeaderRowCell>
      {props.dataKey === props.sortBy && (
        <Icon
          intent={props.dataKey === props.sortBy ? "success" : undefined}
          icon={props.sortDirection === "DESC" ? "caret-down" : "caret-up"}
        />
      )}
    </>
  </Tooltip>
);

/**
 * renders content of a cell with labeled data content
 * @param props
 */
const labeledDataRenderer: TableCellRenderer = props => props.cellData.label;

/**
 * creates a localized renderer for a date cell
 * @param locale
 */
const dateRendererCreator = (locale: string): TableCellRenderer => props =>
  props.cellData
    ? typeof props.cellData === "string"
      ? new Date(parseInt(props.cellData) * 1000).toLocaleDateString(
          locale,
          defaultTimeFormat
        )
      : new Date(props.cellData * 1000).toLocaleDateString(
          locale,
          defaultTimeFormat
        )
    : "";

/**
 * Delivery Status icon
 * @param props
 */
const StatusIcon: React.FC<{ src: string; label: string }> = props => {
  const { t } = useTranslation();

  return (
    <Tooltip content={t(props.label)}>
      <StyledStatusImg src={props.src} />
    </Tooltip>
  );
};

/**
 * Delivery Status Table Cell
 * @param props
 */
const deliveryStateRenderer: TableCellRenderer = props => {
  const data = props.cellData as string;
  switch (data) {
    case "ServerError":
      return (
        <>
          <StatusIcon src={orderOK} label="ORDER_SUCCESS" />
        </>
      );
    case "cancelled":
      return (
        <>
          <StatusIcon src={orderEdited} label="ORDER_EDITED" />
        </>
      );
    case "complete":
      return (
        <>
          <StatusIcon src={orderPen} label="ORDER_NO_CHANGES" />
          <StatusIcon src={orderOK} label="ORDER_SUCCESS" />
          <StatusIcon src={orderOnTime} label="ORDER_ON_TIME" />
        </>
      );
  }
  return null;
};
