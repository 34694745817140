import React from "react";
import styled from "styled-components";

import { $lightGray1 } from "../../theme";
import { FlexBoxColumnProps } from "./FlexTable";

export const StyledTableCell = styled.div`
  min-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 7px;
  border-color: ${$lightGray1};
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
  border-left-width: 1px;
  font-size: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  &:first-child {
    border-left-width: 0;
  }
`;
// eslint-disable-next-line
export const StyledFlexItem = styled(StyledTableCell)<{
  flex: string;
  maxWidth?: number;
  minWidth?: number;
  align?: "flex-start" | "center" | "flex-end";
}>`
${props => (props.align ? "justify-content:" + props.align + ";" : "")}
  flex: ${props => props.flex};
  min-width: ${props =>
    props.maxWidth !== undefined ? props.maxWidth + "px" : 0};
  ${props =>
    props.maxWidth !== undefined ? "max-width:" + props.maxWidth + "px;" : ""};
`;

export interface FlexBoxTableCellProps<T extends object = any> {
  columnProps: FlexBoxColumnProps<T>;
  data: T[keyof T];
  rowData: T;
  rowIndex: number;
  onCellClick?: (props: {
    rowIndex: number;
    rowData: T;
    dataKey: string;
  }) => void;
}

export const FlexBoxCellRenderer: React.FC<FlexBoxTableCellProps> = ({
  columnProps,
  data,
  rowData,
  rowIndex
}) => (
  <StyledFlexItem
    align={columnProps.align}
    flex={columnProps.flex}
    minWidth={columnProps.minWidth}
    maxWidth={columnProps.maxWidth}
    className={columnProps.className}
  >
    {columnProps.children
      ? columnProps.children({ data, rowData, rowIndex })
      : data}
  </StyledFlexItem>
);
