import {
  Button,
  Callout,
  Classes,
  Divider,
  InputGroup,
  Label,
  Spinner
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import styled from "styled-components";

import { ReduxRootType } from "../store/store";
import { setPassword } from "../store/actions/user";
import { actionAddMessages } from "../store/reducers/messages";

interface ResetPasswordProps {
  onCancel?: () => void;
  dispatch: ThunkDispatch<{}, {}, any>;
}

export const StyledOverlay = styled.div`
  position: absolute;
  left: -10px;
  right: -10px;
  top: 0;
  bottom: 0;
  background-color: rgba(60, 75, 88, 0.9);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ResetPassword: React.FC<ResetPasswordProps> = props => {
  const { t } = useTranslation();
  const [oldPass, setOldPass] = useState<undefined | string>();
  const [isLoading, setLoading] = useState(false);
  const [newPass, setNewPass] = useState<undefined | string>();
  const [newVerifyPass, setNewVerifyPass] = useState<undefined | string>();

  const [formError, setFormError] = useState<null | string>(null);
  const [formSuccess, setFormSuccess] = useState(false);

  // reset form error on user input
  useEffect(() => {
    if (formError) {
      setFormError(null);
      setFormSuccess(false);
    }
  }, [newPass, oldPass, newVerifyPass, formError]);

  const onSubmit = async <T extends {}>(e: React.SyntheticEvent<T>) => {
    e.preventDefault();
    // validate passwords
    if (!newVerifyPass || !newPass || !oldPass || newVerifyPass !== newPass) {
      setFormError(t("PASSWORD_SET_FAIL"));
      return;
    }
    setLoading(true);
    try {
      await props.dispatch(setPassword(oldPass, newPass));
      // close dialog
      if (props.onCancel) {
        props.onCancel();
      }
      props.dispatch(
        actionAddMessages([{ msg: t("PASSWORD_SET"), intent: "success" }])
      );
    } catch (e) {
      setFormError(t("PASSWORD_SET_FAIL"));
    }
    setLoading(false);
  };

  return (
    <div style={{ position: "relative" }}>
      {formError && <Callout intent="danger">{formError}</Callout>}
      {/* {formSuccess && <Callout intent="success">{t("PASSWORD_SET")}</Callout>} */}
      <form onSubmit={onSubmit} className={Classes.DIALOG_BODY}>
        <Label>
          {t("PASSWORD_OLD")}
          <InputGroup
            value={oldPass}
            onChange={e => setOldPass(e.target.value)}
            type="password"
            placeholder={t("PASSWORD_OLD")}
          />
        </Label>
        <Divider />
        <Label>
          {t("PASSWORD_NEW")}
          <InputGroup
            value={newPass}
            onChange={e => setNewPass(e.target.value)}
            type="password"
            placeholder={t("PASSWORD_NEW")}
          />
        </Label>
        <Label>
          {t("PASSWORD_NEW_VERIFY")}
          <InputGroup
            value={newVerifyPass}
            onChange={e => setNewVerifyPass(e.target.value)}
            type="password"
            placeholder={t("PASSWORD_NEW_VERIFY")}
          />
        </Label>
      </form>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {props.onCancel && (
            <Button onClick={props.onCancel}>{t("CANCEL")}</Button>
          )}
          <Button intent="primary" onClick={onSubmit}>
            {t("PASSWORD_CHANGE")}
          </Button>
        </div>
      </div>
      {isLoading && (
        <StyledOverlay>
          <Spinner />
        </StyledOverlay>
      )}
    </div>
  );
};

const mapStateToProps = (state: ReduxRootType) => ({});

export default connect(mapStateToProps)(ResetPassword);
