import { Card, Icon, Menu } from "@blueprintjs/core";
import { ACTIVE, MENU_ITEM } from "@blueprintjs/core/lib/esm/common/classes";
import { ItemListRenderer, ItemRenderer, Select } from "@blueprintjs/select";
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { SipShopCoreServicesVoAddress } from "../services/productCatalogue";
import { replaceNewLineWithBr } from "../utils";

interface AddressProps {
  disabled?: boolean;
  items: SipShopCoreServicesVoAddress[];
  selectionId: string | null;
  onSelect: (item: SipShopCoreServicesVoAddress | null) => void;
}

export const AddressSelectComponent = Select.ofType<
  SipShopCoreServicesVoAddress
>();

const StyledAddressCard = styled(Card)<{ disabled?: boolean }>`
  padding: 7px;
  margin-right: 10px;
  min-height: 50px;
  max-width: 450px;
  padding-right: 30px;
  position: relative;
  ${props =>
    props.disabled
      ? `
    background-color: transparent !important;
    color: rgba(167, 182, 194, 0.6);
    cursor: no-allowed;
  `
      : ""}
`;

const StyledAddressDropdownIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 5px;
`;

const AddressSelect: React.FC<AddressProps> = props => {
  const { t } = useTranslation();
  const [
    selectedItem,
    setSelected
  ] = useState<SipShopCoreServicesVoAddress | null>(null);

  useEffect(() => {
    if (!props.selectionId) {
      setSelected(null);
      return;
    }
    const selection = props.items.find(item => item.id === props.selectionId);
    setSelected(selection ? selection : null);
  }, [props.selectionId, props.items]);
  const isDisabled = props.disabled || !props.items || props.items.length === 0;
  return (
    <>
      <AddressSelectComponent
        filterable={false}
        disabled={isDisabled}
        inputProps={{ placeholder: t("DELIVERY_ADDRESSES") }}
        activeItem={selectedItem}
        items={props.items ? props.items : []}
        itemRenderer={addressSuggestItemRenderer}
        itemListRenderer={itemListRenderer}
        onItemSelect={props.onSelect}
        popoverProps={{ minimal: false }}
      >
        <StyledAddressCard disabled={isDisabled} interactive={!props.disabled}>
          {!props.disabled && <StyledAddressDropdownIcon icon="caret-down" />}
          {selectedItem ? (
            <Address address={selectedItem} />
          ) : (
            <p>{t("NO_ADDRESS")}</p>
          )}
        </StyledAddressCard>
      </AddressSelectComponent>
    </>
  );
};

const Address: React.FC<{ address: SipShopCoreServicesVoAddress }> = ({
  address
}) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: replaceNewLineWithBr(address.addressText)
      }}
    />
  );
};

const itemListRenderer: ItemListRenderer<
  SipShopCoreServicesVoAddress
> = props => (
  <Menu ulRef={props.itemsParentRef}>
    {props.items.map((item, index) => props.renderItem(item, index))}
  </Menu>
);

const addressSuggestItemRenderer: ItemRenderer<SipShopCoreServicesVoAddress> = (
  item: SipShopCoreServicesVoAddress,
  itemProps
) => (
  <div
    className={MENU_ITEM + (itemProps.modifiers.active ? " " + ACTIVE : "")}
    onClick={itemProps.handleClick}
    key={item.id}
  >
    <span
      dangerouslySetInnerHTML={{
        __html: replaceNewLineWithBr(item.addressText)
      }}
    />
  </div>
);

export default AddressSelect;
