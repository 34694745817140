import { Button, Icon } from "@blueprintjs/core";
import React, { useCallback } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import styled from "styled-components";

import { localeStringConverter } from "../../i18n";
import {
  SipShopCoreServicesVoItem,
  SipShopCoreServicesVoProduct
} from "../../services/productCatalogue";
import { changeQtyInCart, removeFromCart } from "../../store/actions/cart";
import { selectProductFromCart } from "../../store/actions/shop";
import { editCartItemQty } from "../../store/reducers/cart";
import { setOrderModDel, setOrderModQty } from "../../store/reducers/order";
import {
  localizedPriceStr,
  safelyParseFloat,
  unixTimeToLocalizedStr
} from "../../utils";
import { CartMode, CartProps } from "../Cart";
import ClickToEditField from "../ClickToEditField";
import FlexBoxTable, { FlexBoxColumn } from "../FlexTable/FlexTable";
import { CartItemsBottomRow } from "./CartTable";

const StyledCartFlexBoxTable = styled(FlexBoxTable)<{ disabled?: boolean }>`
  height: calc(100% - 40px) !important;
  min-height: 0px;
  flex: 1 0 auto;
  ${props => (props.disabled ? `color: #666 !important;` : "")}
`;

export const DROPPABLE_CART_ID = "cart";
// import cartDeliveryStatusUnknown from '../assets/images/cartDeliveryStatusUnknown.png';

const ActiveCartQtyEditor: React.FC<{
  dispatch: ThunkDispatch<{}, {}, any>;
  index: number;
  item: SipShopCoreServicesVoItem;
  isEditing?: boolean;
  locale: string;
}> = props => {
  const dispatch = props.dispatch;
  const onEdit = useCallback(() => dispatch(editCartItemQty(props.index)), [
    props.index,
    dispatch
  ]);
  const onEditCancel = useCallback(() => dispatch(editCartItemQty(undefined)), [
    dispatch
  ]);
  const onEditEnd = useCallback(
    (newVal: string) => {
      dispatch(
        changeQtyInCart(props.item.posNoFormatted, props.item.id, newVal)
      );
    },
    [dispatch, props.item.posNoFormatted, props.item.id]
  );

  return (
    <>
      <ClickToEditField
        isEditing={props.isEditing}
        onEdit={onEdit}
        onEditCancel={onEditCancel}
        onEditEnd={onEditEnd}
        value={safelyParseFloat(props.item.cnt, props.locale)}
      />
    </>
  );
};

const ClickToEditRowRenderer = connect()(
  (
    props: {
      rowData: SipShopCoreServicesVoItem;
      data: any;
      rowIndex: number;
    } & { dispatch: ThunkDispatch<{}, {}, any> }
  ) => {
    const { i18n } = useTranslation();
    const browserLocaleStr = localeStringConverter(i18n.language);
    const dispatch = props.dispatch;
    const onEditEnd = useCallback(
      (val: string) =>
        dispatch(
          setOrderModQty({
            posNo: props.rowData.posNoFormatted,
            matId: props.rowData.matId,
            new: val,
            old: props.rowData.cnt
          })
        ),
      [
        props.rowData.posNoFormatted,
        props.rowData.matId,
        props.rowData.cnt,
        dispatch
      ]
    );

    const onClick = useCallback(() => {
      dispatch(
        setOrderModDel({
          posNo: props.rowData.posNoFormatted,
          matId: props.rowData.matId,
          old: props.rowData.cnt
        })
      );
    }, [
      props.rowData.posNoFormatted,
      props.rowData.matId,
      props.rowData.cnt,
      dispatch
    ]);

    return (
      <>
        <ClickToEditField
          isEditing={true}
          noAutofocus
          onEditEnd={onEditEnd}
          value={safelyParseFloat(props.rowData.cnt, browserLocaleStr)}
        />
        <Button onClick={onClick} icon="trash" />
      </>
    );
  }
);

const CartItemActionRowRenderer = connect()(
  (
    props: {
      rowData: SipShopCoreServicesVoItem;
      data: any;
      rowIndex: number;
    } & { dispatch: ThunkDispatch<{}, {}, any> }
  ) => {
    return (
      <Button
        onClick={() =>
          props.dispatch(
            removeFromCart(props.rowData.id, props.rowData.posNoFormatted)
          )
        }
        icon="trash"
      />
    );
  }
);

const CartItemTable: React.FC<CartProps> = props => {
  const { i18n, t } = useTranslation();
  const browserLocaleStr = localeStringConverter(i18n.language);

  const draggableItemIdValue = useCallback(
    (rowData: SipShopCoreServicesVoItem) => rowData.posNoFormatted + rowData.id,
    []
  );

  const onRowClick = useCallback(
    (data: {
      rowData: SipShopCoreServicesVoProduct;
      rowIndex: number;
      data: any;
    }) => props.dispatch(selectProductFromCart(data.rowData.matId)),
    [props.dispatch]
  );
  // useTraceUpdate(props, "CartItemTable -> ");

  // console.log("Rendering shit...");
  return (
    <>
      <Droppable
        droppableId={DROPPABLE_CART_ID}
        isDropDisabled={props.mode === CartMode.orders}
      >
        {provided => (
          <StyledCartFlexBoxTable
            internalScroll
            draggableItemIdValue={draggableItemIdValue}
            disabled={props.mode === CartMode.orders}
            droppableProvided={provided}
            draggable={props.mode === CartMode.shop}
            data={props.order ? props.order.items : []}
          >
            <FlexBoxColumn dataKey="posNoFormatted" name="#" flex="0 0 50px" />
            <FlexBoxColumn
              dataKey="matId"
              name={t("ARTICLE_NUMBER")}
              flex="0 0 140px"
            >
              {props.mode === CartMode.shop
                ? cellProps =>
                    !cellProps.rowData.hasCustomDecor ? (
                      <a onClick={() => onRowClick(cellProps)}>
                        {cellProps.data}
                      </a>
                    ) : (
                      cellProps.data
                    )
                : undefined}
            </FlexBoxColumn>
            <FlexBoxColumn
              dataKey="itemName"
              name={t("DESCRIPTION")}
              flex="1 0 280px"
            />
            {props.mode === CartMode.shop && (
              <FlexBoxColumn name={""} flex="0 0 50px" align="center">
                {cellProps => <CartItemActionRowRenderer {...cellProps} />}
              </FlexBoxColumn>
            )}

            <FlexBoxColumn
              name={t("QUANTITY")}
              flex="0.1 0 80px"
              align="flex-end"
            >
              {cellProps => (
                <ActiveCartQtyEditor
                  isEditing={props.editItemIndex === cellProps.rowIndex}
                  item={cellProps.rowData}
                  index={cellProps.rowIndex}
                  dispatch={props.dispatch}
                  locale={browserLocaleStr}
                />
              )}
            </FlexBoxColumn>
            <FlexBoxColumn
              name={t("UNIT_PRICE")}
              dataKey="packingUnit"
              flex="0 0 60px"
              flex-align="center"
            >
              {cellProps => (cellProps.data ? cellProps.data.label : "")}
            </FlexBoxColumn>

            {props.mode === CartMode.shop && (
              <FlexBoxColumn
                dataKey="pricePerPiece"
                name={t("PRICE_PER_PIECE")}
                flex="0.1 0 80px"
                align="flex-end"
              >
                {cellProps =>
                  localizedPriceStr(
                    cellProps.data,
                    browserLocaleStr,
                    cellProps.rowData.currency
                  )
                }
              </FlexBoxColumn>
            )}
            <FlexBoxColumn
              dataKey="price"
              name={t("PRICE_IN")}
              flex="0.1 0 80px"
              align="flex-end"
            >
              {cellProps =>
                localizedPriceStr(
                  cellProps.data,
                  browserLocaleStr,
                  cellProps.rowData.currency
                )
              }
            </FlexBoxColumn>
            <FlexBoxColumn
              dataKey="deliveryDate"
              name={t("DELIVERY_DATE")}
              flex="0 0 140px"
              align="flex-end"
            >
              {cellProps => {
                if (!cellProps.data) {
                  return (
                    <>
                      {t("UNKNOWN")}{" "}
                      <Icon intent="warning" icon="warning-sign" />
                    </>
                  );
                }
                const date =
                  typeof cellProps.data === "string"
                    ? parseInt(cellProps.data)
                    : cellProps.data;
                let isTooLate = false;
                if (
                  props.mode == CartMode.shop &&
                  props.order &&
                  props.order.favouredDeliveryDate &&
                  parseInt(props.order.favouredDeliveryDate) > 0
                ) {
                  const expireDate = props.order.favouredDeliveryDate
                    ? parseInt(props.order.favouredDeliveryDate)
                    : date;
                  isTooLate = expireDate > 0 && expireDate < date;
                }
                return (
                  <span style={isTooLate ? { color: "red" } : {}}>
                    {cellProps.rowData.deliveryInfo.length > 0 ?
                        <>{cellProps.rowData.deliveryInfo.split("\n").map(elem => <p>{elem}</p>)}</>
                      :
                      unixTimeToLocalizedStr(
                        date,
                        browserLocaleStr,
                        cellProps.rowData.currency)
                    }
                  </span>
                );
              }}
            </FlexBoxColumn>
            {props.mode === CartMode.ordersEdit && (
              <FlexBoxColumn
                name={t("EDIT_COUNT")}
                flex="0 0 140px"
                align="flex-end"
              >
                {cellProps => <ClickToEditRowRenderer {...cellProps} />}
              </FlexBoxColumn>
            )}
          </StyledCartFlexBoxTable>
        )}
      </Droppable>
      {/* Totals row */}
      <CartItemsBottomRow
        mode={props.mode}
        currency={props.order && props.order.currency}
        total={props.order && props.order.total}
        deliveryDate={props.order && props.order.deliveryDate}
      />
    </>
  );
};

export default CartItemTable;
