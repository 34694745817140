import { Button, Label, Menu, Spinner } from "@blueprintjs/core";
import {
  ACTIVE,
  INLINE,
  MENU_ITEM
} from "@blueprintjs/core/lib/esm/common/classes";
import { ItemListRenderer, ItemRenderer, Suggest } from "@blueprintjs/select";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";

import { SipShopCoreServicesVoProduct } from "../../services/productCatalogue";
import { addToCart, addToCartWithMatId } from "../../store/actions/cart";
import {
  setQuickInsertProducts,
  setQuickInsertQuery
} from "../../store/reducers/cart";
import { ReduxRootType } from "../../store/store";
import { $darkGray2 } from "../../theme";
import { ConnectedComponent, debounce } from "../../utils";

const StyledSuggestProductName = styled.small`
  max-width: 300px;
  display: block;
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledQuickInsert = styled.div`
  display: inline-block;
  padding: 9px;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  background-color: ${$darkGray2};
  color: #fff;

  .bp3-label {
    margin-bottom: 0;
  }
`;

const mapStateToProps = (state: ReduxRootType) => ({
  query: state.cart.quickInsert.query,
  products: state.cart.quickInsert.products
});

interface QuickInsertProps extends ConnectedComponent<typeof mapStateToProps> {}

const QuickInsert: React.FC<QuickInsertProps> = props => {
  const { t } = useTranslation();
  const debouncedCallback = useCallback(
    debounce((val: string | null) => {
      val && props.dispatch(addToCartWithMatId(val, true, false));
    }, 500),
    []
  );
  const dispatch = props.dispatch;

  const [query, setQuery] = useState(props.query);

  useEffect(() => setQuery(props.query), [props.query]);
  const onSelect = useCallback((item: SipShopCoreServicesVoProduct) => {
    props.dispatch(addToCart(item));
    props.dispatch(setQuickInsertQuery(""));
  }, []);
  const onQueryChange = useCallback(
    (
      query: string,
      event?: React.ChangeEvent<HTMLInputElement> | undefined
    ) => {
      // onQueryChange triggered twice by blueprintjs
      // must be a bug in the framework
      if (!event) {
        return;
      }

      const cleanQuery = query.trim().toUpperCase();

      // update query text
      dispatch(setQuickInsertQuery(cleanQuery));

      // if query is empty reset selected products
      if (cleanQuery === "") {
        dispatch(setQuickInsertProducts(null));
        return;
      }

      // if query did not change do nothing
      if (props.query === cleanQuery) {
        return;
      }
      // fetch data from server
      debouncedCallback(query);
    },
    [dispatch, debouncedCallback, props.query]
  );

  // const onInputChange = useCallback(
  //   (event: React.KeyboardEvent<HTMLInputElement>) =>
  //     debouncedCallback(props.query),
  //   [props.query]
  // );
  return (
    <StyledQuickInsert>
      <form onSubmit={e => e.preventDefault()}>
        <Label className={INLINE}>
          {t("QUICKINSERT")}
          <ProductSuggest
            resetOnSelect
            onQueryChange={onQueryChange}
            inputProps={{
              placeholder: t("PRODUCT_NUMBER"),
              rightElement: (
                <Button
                  type="submit"
                  intent="primary"
                  disabled={!query}
                  onClick={() =>
                    query && props.dispatch(addToCartWithMatId(query))
                  }
                >
                  {t("INSERT")}
                </Button>
              )
            }}
            query={query}
            popoverProps={{
              minimal: true,
              captureDismiss: false
            }}
            scrollToActiveItem={true}
            onItemSelect={onSelect}
            // itemPredicate={(query, item) => item.matId.indexOf(query) !== -1}
            itemRenderer={itemRenderer}
            itemListRenderer={itemListRenderer}
            inputValueRenderer={item => item.matId}
            items={props.products ? props.products : []}
            selectedItem={
              props.products && query
                ? props.products.find(item => item.matId === query)
                : null
            }
          />
          {/* <InputGroup
            className={DARK}
            value={value}
            onChange={e => setValue(e.currentTarget.value)}
            placeholder={}
          /> */}
        </Label>
      </form>
    </StyledQuickInsert>
  );
};

export default connect(mapStateToProps)(QuickInsert);

const QuickInsertMenuRendererWrapper: React.FC<{
  items: SipShopCoreServicesVoProduct[] | null | undefined;
  err: string | undefined;
  isLoading: boolean;
}> = props => {
  if (props.err) {
    return <p>{props.err}</p>;
  }
  if (props.isLoading) {
    return (
      <>
        <br />
        <Spinner size={25} />
        <br />
      </>
    );
  }
  if (props.items === undefined) {
    return null;
    // return (
    //   <>
    //     <Spinner size={Spinner.SIZE_SMALL} />
    //   </>
    // );
  }

  return <>{props.children}</>;
};

const ConnectedQuickInsertMenuRendererWrapper = connect(
  (state: ReduxRootType) => ({
    items: state.cart.quickInsert.products,
    err: state.cart.quickInsert.error,
    isLoading:
      state.cart.quickInsert.products === undefined &&
      state.cart.quickInsert.query !== ""
  })
)(QuickInsertMenuRendererWrapper);

export const ProductSuggest = Suggest.ofType<SipShopCoreServicesVoProduct>();

const itemListRenderer: ItemListRenderer<
  SipShopCoreServicesVoProduct
> = props => (
  <Menu ulRef={props.itemsParentRef}>
    <ConnectedQuickInsertMenuRendererWrapper>
      {props.filteredItems.map((item, index) => props.renderItem(item, index))}
    </ConnectedQuickInsertMenuRendererWrapper>
  </Menu>
);

const itemRenderer: ItemRenderer<SipShopCoreServicesVoProduct> = (
  item,
  itemProps
) => {
  return (
    <div
      className={MENU_ITEM + (itemProps.modifiers.active ? " " + ACTIVE : "")}
      onClick={itemProps.handleClick}
      key={item.id}
    >
      <span>
        <b>{item.matId}</b>
        <StyledSuggestProductName>{item.name}</StyledSuggestProductName>
      </span>
    </div>
  );
};
