import { Dialog, Divider, Icon, H2 } from "@blueprintjs/core";
import { DIALOG_BODY } from "@blueprintjs/core/lib/esm/common/classes";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { actionClearMessages } from "../store/reducers/messages";
import { ReduxRootType } from "../store/store";
import { ConnectedComponent } from "../utils";

const mapStateToProps = (state: ReduxRootType) => ({
  messages: state.messages
});

interface MessagesDialogProps
  extends ConnectedComponent<typeof mapStateToProps> {}

const MessagesDialog: React.FC<MessagesDialogProps> = ({
  messages,
  dispatch
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentMessages, setCurrentMessages] = useState(messages);

  // if new messages came in show them
  useEffect(() => {
    if (messages && messages.length > 0 && !isOpen) {
      setIsOpen(true);
      setCurrentMessages(messages);
      dispatch(actionClearMessages());
    }
  }, [messages, isOpen, dispatch]);

  const { t } = useTranslation();

  return (
    <Dialog
      isOpen={isOpen}
      isCloseButtonShown
      onClose={() => setIsOpen(false)}
      title={t("SYSTEM_MESSAGES")}
    >
      <div className={DIALOG_BODY}>
        {currentMessages &&
          currentMessages.map((msg, i) => (
            <React.Fragment key={msg.id}>
              {i > 0 && <Divider />}
              {msg.icon ? (
                <div style={{ textAlign: "center" }}>
                  <br />
                  <Icon intent={msg.intent} iconSize={33} icon={msg.icon} />
                  <br />
                  <H2>{msg.localize ? t(msg.msg) : msg.msg}</H2>
                </div>
              ) : (
                <p>{msg.localize ? t(msg.msg) : msg.msg}</p>
              )}
            </React.Fragment>
          ))}
      </div>
    </Dialog>
  );
};

export default connect(mapStateToProps)(MessagesDialog);
