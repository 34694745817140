import { Button, Checkbox, Label } from "@blueprintjs/core";
import { DARK, INLINE } from "@blueprintjs/core/lib/esm/common/classes";
import { DateInput } from "@blueprintjs/datetime";
import i18next from "i18next";
import moment from "moment";
import "moment/locale/cs";
// Make sure moment.js has the required locale data
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/hu";
import "moment/locale/it";
import "moment/locale/pl";
import "moment/locale/ro";
import "moment/locale/ru";
import React, {useEffect, useState} from "react";
// Include the locale utils designed for moment
import MomentLocaleUtils from "react-day-picker/moment";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";
import AddressSelect from "../../components/AddressSelect";
import Confirm from "../../components/Confirm";
import { localeStringConverter } from "../../i18n";
import { CustomDecorSelect } from "../../pages/shop/CustomDecors";
import {
    CurrentCart,
    SipShopCoreServicesVoCartEnv,
    SipShopCoreServicesVoLabeledData
} from "../../services/productCatalogue";
import {
  debouncedUpdateCartState,
  resetCart,
  updateCartState
} from "../../store/actions/cart";
import {OrderModificationState, setOrderModValue} from "../../store/reducers/order";
import { ReduxRootType } from "../../store/store";
import {
  ConnectedComponent,
  dateFromUnixTimestampString,
  defaultTimeFormat,
  getUnixTimestampt
} from "../../utils";
import { CartMode } from "../Cart";

const StyledInputSubtitle = styled.small`
  padding-bottom: 15px;
  padding-left: 4px;
  display: inline-block;
`;

const InfoBlock = styled.div`
  display: inline-block;
  padding-right: 10px;
  &:last-child {
    padding-right: 0;
  }
  text-align: left;
`;
const StyledInfoBlockSpan = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: flex-end;
  padding: 0px;
  padding-top: 7px;
`;

const StyledTopBar = styled.div`
  color: #fff;
  &::after {
    content: "";
    clear: both;
    display: table;
    clear: both;
  }

  .bp3-input-group.bp3-disabled input {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(16, 22, 26, 0.35) !important;
  }

  .bp3-control.bp3-checkbox.bp3-disabled .bp3-control-indicator {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(16, 22, 26, 0.35) !important;
  }
`;

const EqualLabelWidthBlock = styled(InfoBlock)<{ width: number }>`
  .bp3-label span {
    display: inline-block;
    min-width: ${props => props.width}px;
  }
`;

const mapStateToProps = (state: ReduxRootType) => ({
  env: state.cart.env,
  modifications: state.orders.modifications
});

interface OrderInfoBarProps
  extends ConnectedComponent<typeof mapStateToProps, Partial<CurrentCart>> {
  isVertical?: boolean;
  mode: CartMode;
}

const parseData = (str: string, locale?: string) => moment(str).toDate();
const formatDate = (date: Date, locale?: string) =>
  date.toLocaleDateString(
    locale ? localeStringConverter(locale) : undefined,
    defaultTimeFormat
  );

const OrderInfoBar: React.FC<OrderInfoBarProps> = props => {
  const { t } = useTranslation();

  const selectedData =
    props.modifications && props.modifications.deliveryDate
      ? "" + props.modifications.deliveryDate
      : props.mode === CartMode.shop
      ? props.favouredDeliveryDate
      : props.deliveryDate;

  let deliveryDate =
    selectedData && selectedData !== "0"
      ? dateFromUnixTimestampString(selectedData)
      : new Date();

  let minDate = props.mode === CartMode.shop ? new Date() : undefined;

  if (props.mode === CartMode.shop && deliveryDate < new Date()) {
    deliveryDate = new Date();
  }

  const language = i18next.language.replace("cz_CZ", "cs");

  const handleAddressSapId = (
      env: SipShopCoreServicesVoCartEnv|null,
      modifications: OrderModificationState|undefined,
      address: SipShopCoreServicesVoLabeledData|undefined,
  ): string => {
    if (props.env && props.env.deliveryAddresses.length > 0) {
      if (props.modifications && props.modifications.deliveryAddress) {
          for (let i = 0; i < props.env.deliveryAddresses.length; i++) {
              if (props.env.deliveryAddresses[i].id === props.modifications.deliveryAddress.data) {
                  return props.env.deliveryAddresses[i].sapId;
              }
          }
      }
      if (props.deliveryAddress) {
          for (let i = 0; i < props.env.deliveryAddresses.length; i++) {
              if (props.env.deliveryAddresses[i].id === props.deliveryAddress.data) {
                  return props.env.deliveryAddresses[i].sapId;
              }
          }
      }
    }
    return '';
  };
  const [addressSapId, setAddressSapId] = useState(handleAddressSapId(props.env, props.modifications, props.deliveryAddress));
  useEffect(() => {
      let updSapId = handleAddressSapId(props.env, props.modifications, props.deliveryAddress);
      if (updSapId != addressSapId) {
          setAddressSapId(updSapId);
      }
  }, [props.env, props.modifications, props.deliveryAddress]);
  return (
    <StyledTopBar className={DARK}>
      <StyledInfoBlockSpan>
          <EqualLabelWidthBlock width={110}>
          <Label className={INLINE}>
              <span>{t("CONSIGNEE")} {addressSapId}</span>
          </Label>
          </EqualLabelWidthBlock>
        <InfoBlock>
          {props.deliveryAddress ? (
            <AddressSelect
              disabled={props.mode === CartMode.orders}
              items={props.env ? props.env.deliveryAddresses : []}
              selectionId={
                props.modifications && props.modifications.deliveryAddress
                  ? props.modifications.deliveryAddress.data
                  : props.deliveryAddress
                  ? props.deliveryAddress.data
                  : null
              }
              onSelect={item =>
                props.mode === CartMode.shop
                  ? props.dispatch(
                      updateCartState(
                        "deliveryAddress",
                        item ? { data: item.id } : null
                      )
                    )
                  : props.dispatch(
                      setOrderModValue({
                        deliveryAddress:
                          item &&
                          props.deliveryAddress &&
                          props.deliveryAddress.data !== item.id
                            ? { data: item.id, label: item.addressText }
                            : undefined
                      })
                    )
              }
            />
          ) : (
            ""
          )}
        </InfoBlock>
        <EqualLabelWidthBlock width={110}>
          <CustomDecorSelect
            inline
            selectedItem={
              props.modifications && props.modifications.deliveryType
                ? props.modifications.deliveryType
                : props.deliveryType
            }
            disabled={props.mode === CartMode.orders}
            label={t("ORDER_TYPE")}
            items={props.env ? props.env.deliveryTypes : []}
            onSelect={item =>
              props.mode === CartMode.shop
                ? props.dispatch(updateCartState("deliveryType", item))
                : props.dispatch(
                    setOrderModValue({
                      deliveryType: item ? item : undefined
                    })
                  )
            }
          />
          <Label className={INLINE}>
            <span>{t("DELIVERY_DATE_WISH")}</span>
            <DateInput
              localeUtils={MomentLocaleUtils}
              // maxDate is 32-bit date limit otherwise server will return negative timestamps
              maxDate={moment(new Date(2147000000000)).toDate()}
              minDate={minDate}
              locale={language}
              parseDate={parseData}
              formatDate={formatDate}
              onChange={date =>
                date &&
                (props.mode === CartMode.shop
                  ? props.dispatch(
                      debouncedUpdateCartState(
                        "favouredDeliveryDate",
                        getUnixTimestampt(date)
                      )
                    )
                  : props.dispatch(
                      setOrderModValue({
                        deliveryDate: getUnixTimestampt(date)
                      })
                    ))
              }
              value={deliveryDate}
              disabled={props.mode === CartMode.orders}
            />
          </Label>
        </EqualLabelWidthBlock>
        <InfoBlock style={{ maxWidth: 250 }}>
          <Checkbox
            checked={
              props.modifications &&
              props.modifications.completeDelivery !== undefined
                ? props.modifications.completeDelivery
                : !!props.completeDelivery
            }
            disabled={props.mode === CartMode.orders}
            name="COMPLETE_DELIVERY"
            label={t("COMPLETE_DELIVERY")}
            onChange={e => {
              props.dispatch(
                props.mode === CartMode.shop
                  ? debouncedUpdateCartState(
                      "completeDelivery",
                      !props.completeDelivery
                    )
                  : setOrderModValue({
                      completeDelivery: !(props.modifications &&
                      props.modifications.completeDelivery !== undefined
                        ? props.modifications.completeDelivery
                        : !!props.completeDelivery)
                    })
              );
            }}
          />

          <StyledInputSubtitle>
            {t("COMPLETE_DELIVERY_DESC")}<br/>{t("COMPLETE_DELIVERY_DATE_HINT")}
          </StyledInputSubtitle>
        </InfoBlock>
        {props.mode === CartMode.shop && (
          <InfoBlock>
            <Confirm
              trigger={
                <Button icon="trash" intent="danger">
                  {t("CART_RESET")}
                </Button>
              }
              title={t("CART_RESET") + "?"}
              msg={t("CART_RESET_CONFIRM")}
              onCancel={() => {}}
              confirmIntent="danger"
              onConfirm={() => props.dispatch(resetCart())}
              cancelLabel={t("NO")}
              confirmLabel={t("YES")}
            />
          </InfoBlock>
        )}
      </StyledInfoBlockSpan>
    </StyledTopBar>
  );
};

export default connect(mapStateToProps)(OrderInfoBar);
