import { routerMiddleware, RouterState } from "connected-react-router";
import { createBrowserHistory } from "history";
import { ReactText } from "react";
import { AnyAction, applyMiddleware, compose, createStore, Store } from "redux";
import {
  createTransform,
  Persistor,
  persistReducer,
  persistStore
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";

import { rootReducer } from "./reducers";
import { BottomCartState } from "./reducers/bottomCart";
import { CartState } from "./reducers/cart";
import { ErrorState } from "./reducers/error";
import { MessageState } from "./reducers/messages";
import { OrderState } from "./reducers/order";
import { SearchState } from "./reducers/search";
import { ShopState } from "./reducers/shop";
import { ToastState } from "./reducers/toasts";
import { UserState } from "./reducers/user";

export const actionCreator = <
  T extends string,
  P extends object | string | number | boolean | undefined | null
>(
  type: T,
  payload: P
) => ({ type, payload });

export interface ReduxRootType {
  user: UserState | null;
  shop: ShopState;
  errors: ErrorState;
  cart: CartState;
  toasts: ToastState;
  messages: MessageState;
  orders: OrderState;
  bottomCart: BottomCartState;
  router: RouterState;
  search: SearchState;
}

const bottomCartTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    // convert mySet to an Array.
    return inboundState;
  },
  // transform state being rehydrated
  (outboundState: BottomCartState, key: ReactText): BottomCartState => {
    return {
      ...outboundState,
      size: outboundState.size
        ? Math.min(window.innerHeight * 0.7, outboundState.size)
        : outboundState.size
    };
  },
  // define which reducers this transform gets called for.
  { whitelist: ["bottomCart"] }
);

// const loggerMiddleware = createLogger();

const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    window &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

// config for refux persist
const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "errors",
    "orders",
    "shop",
    "router",
    "bottomCart",
    "cart",
    "search"
  ],
  transform: [bottomCartTransform]
};

export const history = createBrowserHistory();

export const storeCreator = (): {
  store: Store<ReduxRootType, AnyAction>;
  persistor: Persistor;
} => {
  const middlewares = [
    // loggerMiddleware,
    thunkMiddleware,
    routerMiddleware(history)
  ];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const composedEnhancers = composeEnhancers(middlewareEnhancer);
  const reducer = persistReducer(persistConfig, rootReducer(history));
  const store = createStore(reducer, {}, composedEnhancers);
  const persistor = persistStore(store);
  return { store, persistor };
};
