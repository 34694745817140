import { ThunkDispatch } from "redux-thunk";

import { asyncActionWrapper, getOrderService } from "../../services/api";
import {
  SipShopCoreServicesVoDocumentDownload,
  SipShopCoreServicesVoOrder
} from "../../services/productCatalogue";
import { downloadFileFromURL } from "../../utils";
import { ErrorType } from "../reducers/error";
import { setOrders, setOrdersMatIdSearch } from "../reducers/order";
import { addErrorToast, addToast } from "../reducers/toasts";
import { ReduxRootType } from "../store";

export const loadOrders = (matId?: string) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  dispatch(setOrders(null));
  const call = getOrderService().operations.getOrders(matId);
  try {
    const orders = (await asyncActionWrapper(
      call,
      dispatch,
      ErrorType.loadOrders
    )) as SipShopCoreServicesVoOrder[];
    dispatch(setOrders(orders));
  } catch (e) {
    console.error(e);
  }
};

export const searchOrderForMatId = (matId?: string) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  // load orders
  const call = getOrderService().operations.getOrders(matId);
  try {
    const orders = (await asyncActionWrapper(
      call,
      dispatch,
      ErrorType.loadOrders
    )) as SipShopCoreServicesVoOrder[];
    dispatch(setOrdersMatIdSearch(orders));
  } catch (e) {
    console.error(e);
  }
};

/**
 *
 */
export const modifyOrderViaEmail = (
  orderId: string,
  subject: string,
  msg: string
) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  dispatch(setOrders(null));
  const call = getOrderService().operations.modifyOrderViaEmail(
    orderId,
    subject,
    msg
  );
  try {
    const result = await asyncActionWrapper(
      call,
      dispatch,
      ErrorType.modifyOrderFailed
    );
    if (!result) {
      dispatch(addErrorToast("Failed to modify order"));
    } else {
      dispatch(
        addToast({
          message: "Email sent",
          icon: "tick-circle",
          intent: "success"
        })
      );
      dispatch(loadOrders());
    }
  } catch (e) {
    console.error(e);
    dispatch(addErrorToast("Failed to modify order"));
  }
};

/**
 * export order as csv and download the file
 */
export const exportOrder = (
  orderId: string,
  type: "PDF" | "CSV" = "CSV"
) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  const call =
    type === "CSV"
      ? getOrderService().operations.exportOrder(orderId)
      : getOrderService().operations.exportOrderPdf(orderId);

  try {
    const result = (await asyncActionWrapper(
      call,
      dispatch,
      ErrorType.exportCart
    )) as SipShopCoreServicesVoDocumentDownload;
    downloadFileFromURL(result.tmpName, result.name);
  } catch (e) {
    console.log(e);
  }
};
