import {
  Button,
  Callout,
  Card,
  Dialog,
  H5,
  InputGroup
} from "@blueprintjs/core";
import {
  DIALOG_BODY,
  DIALOG_FOOTER,
  DIALOG_FOOTER_ACTIONS
} from "@blueprintjs/core/lib/esm/common/classes";
import React, { useCallback, useEffect, useReducer, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import styled from "styled-components";

import { localeStringConverter } from "../../i18n";
import { asyncActionWrapper, getOrderService } from "../../services/api";
import { SipShopCoreServicesVoOrder } from "../../services/productCatalogue";
import { resetCartToOrder, uploadCart } from "../../store/actions/cart";
import { loadOrders } from "../../store/actions/order";
import { ErrorType } from "../../store/reducers/error";
import { actionAddMessages } from "../../store/reducers/messages";
import orderReducer, {
  setOrderDetail,
  setOrders,
  setOrdersQuery,
  setOrdersSort
} from "../../store/reducers/order";
import { addErrorToast } from "../../store/reducers/toasts";
import { ReduxRootType } from "../../store/store";
import { $lightGray1, $lightGray3 } from "../../theme";
import { ConnectedComponent, debounce, localizedPriceStr } from "../../utils";
import FlexBoxTable, { FlexBoxColumn } from "../FlexTable/FlexTable";
import OrderTable from "../OrderTable";

const ImportDialogContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  min-height: 70vh;
  padding-bottom: 25px;
`;

const StyledImportOptionsWrapper = styled.div`
  flex: 1 0 300px;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  > div {
    flex: 0 1 auto;
  }
`;

const StyledImportTableWrapper = styled.div`
  flex: 1 0 400px !important;
  margin-top: 7px;
  margin-bottom: 15px;
`;

const StyledDialogOrderTable = styled(OrderTable)`
  padding: 0;
  background-color: ${$lightGray1};
  min-height: 0;
`;

const StyledPreviewWrapper = styled.div`
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid ${$lightGray1};
  flex: 1 0 300px;

  display: flex;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  max-height: 100%;
  > div {
    height: 100%;
    background-color: ${$lightGray3};
    border: 1px solid ${$lightGray1};
    border-radius: 4px;
    flex: 1 1 auto;
  }
`;

const mapStateToProps = (state: ReduxRootType) => ({
  orders: state.orders.entries ? state.orders.entries : []
});

interface CartImportDialogProps
  extends ConnectedComponent<typeof mapStateToProps> {}

const CartImportDialog: React.FC<CartImportDialogProps> = props => {
  // Use state
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  // create a local reducer based on the order page reducer.
  // orderDispatch does not interact with redux but with reacts
  // build in redux like functionality. This a state limited to this instance
  // of the component
  const [orderState, orderDispatch] = useReducer(orderReducer, {
    entries: props.orders,
    editMode: false
  });

  // update reducer order
  useEffect(() => {
    orderDispatch(setOrders(props.orders));
  }, [props.orders]);
  const deboucedUpdate = useCallback(
    debounce((val: string) => orderDispatch(setOrdersQuery(val)), 100),
    []
  );

  const browserLocale = localeStringConverter(i18n.language);
  return (
    <>
      <Button onClick={() => setIsOpen(true)} minimal icon="import">
        {t("IMPORT")}
      </Button>
      <Dialog
        style={{ width: "90vw", height: "90vh", minHeight: 600 }}
        icon="import"
        title={t("CART_IMPORT")}
        onOpening={() => props.dispatch(loadOrders())}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <ImportDialogContainer className={DIALOG_BODY}>
          <StyledImportOptionsWrapper>
            <h3>{t("ORDERS")}</h3>
            <InputGroup
              defaultValue={orderState.filter && orderState.filter.query}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                deboucedUpdate(e.currentTarget.value)
              }
              placeholder={t("DO_SEARCH")}
              leftIcon="search"
            />
            <StyledImportTableWrapper>
              <StyledDialogOrderTable
                selectedId={
                  orderState.detail ? orderState.detail.id : undefined
                }
                widthInset={0}
                heightInset={0}
                onSort={options =>
                  orderDispatch(
                    setOrdersSort(options.sortBy, options.sortDirection)
                  )
                }
                onOrderSelected={order => {
                  orderDispatch(setOrderDetail(null));
                  loadOrderDetails(props.dispatch, order.id)
                    .then(order => orderDispatch(setOrderDetail(order)))
                    .catch(err => {
                      orderDispatch(setOrderDetail(undefined));
                      if (err.msg) {
                        props.dispatch(actionAddMessages([{ msg: err.msg }]));
                      }
                    });
                }}
                tableProps={{ headerHeight: 30 }}
                sortBy={orderState.sort && orderState.sort.key}
                sortDirection={orderState.sort && orderState.sort.direction}
                orders={
                  orderState.filter
                    ? orderState.filter.results
                    : orderState.entries
                    ? orderState.entries
                    : []
                }
                omitColumns={[
                  "owner",
                  "status",
                  "deliveryAddress",
                  "cntDocuments",
                  "sapOrderId"
                ]}
              />
            </StyledImportTableWrapper>
            <CsvUploadCard
              dispatch={props.dispatch}
              onComplete={() => setIsOpen(false)}
            />
          </StyledImportOptionsWrapper>
          <StyledPreviewWrapper>
            <h3>{t("PREVIEW")}</h3>
            <div>
              <FlexBoxTable
                internalScroll
                loading={orderState.detail === null}
                data={orderState.detail ? orderState.detail.items : []}
              >
                <FlexBoxColumn
                  dataKey="posNoFormatted"
                  name="#"
                  flex="0 0 60px"
                />
                <FlexBoxColumn
                  dataKey="matId"
                  name={t("ARTICLENUMBER")}
                  minWidth={130}
                  flex="1 0 140px"
                />
                <FlexBoxColumn
                  dataKey="itemName"
                  name={t("DESCRIPTION")}
                  flex="1 1 280px"
                />
                <FlexBoxColumn
                  dataKey="cnt"
                  name={t("QUANTITY")}
                  flex="1 0 100px"
                >
                  {cellProps =>
                    localizedPriceStr(
                      cellProps.data,
                      browserLocale,
                      cellProps.rowData.currency
                    )
                  }
                </FlexBoxColumn>
              </FlexBoxTable>
            </div>
          </StyledPreviewWrapper>
        </ImportDialogContainer>
        <div className={DIALOG_FOOTER}>
          <div className={DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setIsOpen(false)}>{t("CANCEL")}</Button>
            <Button
              disabled={!orderState.detail}
              onClick={() => {
                if (!orderState.detail) {
                  return;
                }
                props.dispatch(resetCartToOrder(orderState.detail));
                setIsOpen(false);
              }}
              intent="success"
            >
              {t("APPLY")}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps)(CartImportDialog);

const CsvUploadCard: React.FC<{
  dispatch: Dispatch;
  onComplete?: () => void;
  onFailed?: () => void;
}> = ({ dispatch, onComplete, onFailed }) => {
  const { t } = useTranslation();
  const fileInputRuf = useRef<HTMLInputElement | null>(null);
  const uploadCartPath = "/foomo/modules/SipShop.Core/upload.php";
  //getCartService().server + getCartService().endPoint + "/uploadCartData";

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        uploadFile(e.target.files[0], uploadCartPath, dispatch)
          .then(() => onComplete && onComplete())
          .catch(() => onFailed && onFailed());
      }
    },
    [uploadCartPath, onComplete, onFailed, dispatch]
  );
  return (
    <Card>
      <H5>{t("IMPORT_CSV")}</H5>
      <Callout style={{ marginBottom: 15 }} intent="warning">
        {t("CART_IMPORT_COPY")}
      </Callout>
      <Button
        onClick={() => fileInputRuf.current && fileInputRuf.current.click()}
        icon="cloud-upload"
      >
        {t("IMPORT_CSV")}
      </Button>
      <input
        onChange={onChange}
        ref={fileInputRuf}
        type="file"
        style={{ display: "none" }}
      />
    </Card>
  );
};

/**
 * upload a file using fetch
 * @param file
 * @param url
 * @param dispatch
 */
const uploadFile = async (
  file: File,
  url: string,
  dispatch: ThunkDispatch<{}, {}, any>
) => {
  const formData = new FormData();
  formData.append("data", file);

  const onError = () => dispatch(addErrorToast("UPLOAD_FILE_ERROR", true));
  try {
    const uploadRef = await fetch(url, { method: "POST", body: formData }).then(
      result => {
        if (!result.ok) {
          throw new Error("non 200 status code");
        }
        return result.text();
      }
    );
    await dispatch(uploadCart(uploadRef));
  } catch (e) {
    onError();
  }
};
/**
 * load order detail info
 * @param dispatch
 * @param orderId
 */
const loadOrderDetails = async (
  dispatch: Dispatch,
  orderId: string
): Promise<SipShopCoreServicesVoOrder> => {
  const call = getOrderService().operations.getOrderDetails(orderId);

  return asyncActionWrapper(
    call,
    dispatch,
    ErrorType.loadOrderDetail
  ) as Promise<SipShopCoreServicesVoOrder>;
};
