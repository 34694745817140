import { Button, Classes, MenuItem } from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CZ from "../assets/images/CZ.png";
import DE from "../assets/images/DE.png";
import ES from "../assets/images/ES.png";
import FR from "../assets/images/FR.png";
import GB from "../assets/images/GB.png";
import HU from "../assets/images/HU.png";
import IT from "../assets/images/IT.png";
import PL from "../assets/images/PL.png";
import PT from "../assets/images/PT.png";
import RO from "../assets/images/RO.png";
import RU from "../assets/images/RU.png";
import { SipShopCoreServicesVoLabeledData } from "../services/productCatalogue";
import { LanguageCode } from "../store/actions/user";

const StyledLanguageButton = styled(Button)`
  .bp3-button-text {
    @media screen and (max-width: 1450px) {
      max-width: 0;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const languages = [
  { label: "Deutsch", data: LanguageCode.DE },
  { label: "English", data: LanguageCode.EN },
  { label: "Español", data: LanguageCode.ES },
  { label: "Français", data: LanguageCode.FR },
  { label: "Italiano", data: LanguageCode.IT },
  { label: "Česky", data: LanguageCode.CZ },
  { label: "Magyar", data: LanguageCode.HU },
  { label: "Polski", data: LanguageCode.PL },
  { label: "Português", data: LanguageCode.PT },
  { label: "Românǎ", data: LanguageCode.RO },
  { label: "Русский", data: LanguageCode.RU }
];

interface LanguageDropdownProps {
  onLanguageChanged: (lang: string) => void;
}

const LanguageDropdown: React.FC<LanguageDropdownProps> = props => {
  const { i18n } = useTranslation();
  const curLang = getLanguageItem(i18n.language as LanguageCode);
  if (!curLang) {
    console.error("Current language is unknown:", i18n.language);
    return null;
  }
  return (
    <ItemSelect
      filterable={false}
      items={languages}
      popoverProps={{ minimal: true }}
      itemRenderer={languageSelectItemRenderer}
      onItemSelect={item => props.onLanguageChanged(item.data)}
    >
      <StyledLanguageButton
        className={Classes.MINIMAL}
        text={curLang.label}
        rightIcon="caret-down"
        icon={
          <img
            alt={curLang.label}
            style={{ height: 20 }}
            src={getLanguageIcon(curLang.data)}
          />
        }
      />
    </ItemSelect>
  );
};

export default LanguageDropdown;

const ItemSelect = Select.ofType<SipShopCoreServicesVoLabeledData>();

const languageSelectItemRenderer: ItemRenderer<
  SipShopCoreServicesVoLabeledData
> = (item, props) => {
  return (
    <MenuItem
      onClick={props.handleClick}
      key={item.data}
      icon={
        <img
          style={{ height: 20 }}
          alt={item.label}
          src={getLanguageIcon(item.data)}
        />
      }
      text={item.label}
    />
  );
};

const getLanguageItem = (lang: LanguageCode) =>
  languages.find(item => item.data === lang);

const getLanguageIcon = (lang: string | LanguageCode) => {
  switch (lang) {
    case LanguageCode.DE:
      return DE;
    case LanguageCode.FR:
      return FR;
    case LanguageCode.CZ:
      return CZ;
    case LanguageCode.EN:
      return GB;
    case LanguageCode.ES:
          return ES;
    case LanguageCode.HU:
      return HU;
    case LanguageCode.IT:
      return IT;
    case LanguageCode.PL:
      return PL;
    case LanguageCode.PT:
      return PT;
    case LanguageCode.RO:
      return RO;
    case LanguageCode.RU:
      return RU;
    default:
      console.error("unknown language code:", lang);
      return "";
  }
};
