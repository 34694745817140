import {SipShopCoreServicesVoSearchResult, SipShopCoreServicesVoTreeEntry} from '../../services/productCatalogue';
import {actionCreator} from '../store';
import {Subtract} from './shop';

export const SET_SEARCH = 'SET_SEARCH';

export const setSearchAction =
    (search: Partial<SearchState>, merge: boolean = true) =>
        actionCreator(SET_SEARCH, {search, merge});

export interface SearchState extends Subtract <
                                         SipShopCoreServicesVoSearchResult,
{
  tree: SipShopCoreServicesVoTreeEntry[];
  // series?: SipShopCoreServicesVoLabeledData[];
}
> {
  tree?: SipShopCoreServicesVoTreeEntry[]|null;
}

type ActionTypes = ReturnType<typeof setSearchAction>;

export const isSearchValid = (state: SearchState): boolean =>
    !!((state.searchText && state.searchText !== '') || state.seriesSelected ||
       state.productGroupId || state.matId || state.sale)

const searchReducer =
    (state: SearchState = {}, action: ActionTypes): SearchState => {
      switch (action.type) {
        case SET_SEARCH:
          return action.payload.merge ? {...state, ...action.payload.search} : {
            ...action.payload.search,
          };
        default:
          return state
      }
    };

export default searchReducer;