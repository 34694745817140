import { Tooltip } from "@blueprintjs/core";
import React, { CSSProperties } from "react";
import styled from "styled-components";

import { $lightGray1, $lightGray2, $lightGray4 } from "../../theme";
import { StyledFlexItem } from "./FlexCell";
import { StyledFlexRow } from "./FlexRow";
import { FlexBoxColumnProps } from "./FlexTable";

export const StyledFlexHeaderRow = styled(StyledFlexRow)<{ scroll?: boolean }>`
  background: linear-gradient(${$lightGray4}, ${$lightGray2});
  font-weight: bold;
  /* add this for force constant spacing to content by also adding a scroll bar */
  border-width: 0;
  border-bottom-width: 2px;
  border-color: ${$lightGray1};
  border-style: solid;
  ${props =>
    props.scroll
      ? `
    min-height: 35px; overflow-y: scroll;
  `
      : ""}
`;

/**
 * Tooltip that is 100% of parents width and overflows with ellipsis
 */
export const StyledOverflowFlexTooltip = styled(Tooltip)`
  overflow: hidden;

  .bp3-popover-target {
    width: 100%;

    > span {
      width: 100%;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
    }
  }
`;
const FlexHeader: React.FC<{
  className?: string;
  columns: FlexBoxColumnProps[];
  internalScroll?: boolean;
  style?: CSSProperties;
}> = props => (
  <StyledFlexHeaderRow
    style={props.style}
    scroll={props.internalScroll}
    className={props.className}
  >
    {props.columns.map((columnProps, index) => (
      <StyledFlexItem
        key={index}
        flex={columnProps.flex}
        align={columnProps.align}
        minWidth={columnProps.minWidth}
        maxWidth={columnProps.maxWidth}
        className={columnProps.className}
      >
        <StyledOverflowFlexTooltip content={<>{columnProps.name}</>}>
          <>{columnProps.name}</>
        </StyledOverflowFlexTooltip>
      </StyledFlexItem>
    ))}
  </StyledFlexHeaderRow>
);

export default FlexHeader;
