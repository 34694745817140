import React, { useCallback } from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

import { SipShopCoreServicesVoItem } from "../../services/productCatalogue";
import { FlexBoxCellRenderer, StyledTableCell } from "./FlexCell";
import { FlexBoxColumnProps } from "./FlexTable";
import { $green2 } from "../../theme";

// eslint-disable-next-line
export const StyledFlexRow = styled.div<{
  striped?: boolean;
  isDragging?: boolean;
  isActive?: boolean;
}>`
  width: 100%;
  display: flex;

  flex-direction: row;
  align-content: stretch;
  align-items: stretch;
  background-color: #fff;

  &:last-child ${StyledTableCell} {
    border-bottom-width: 0;
  }

  ${props =>
    props.isActive ? `background-color: ${$green2}; color: #fff;` : ""}

  ${props => (props.isDragging ? `>div { border-top-width: 1px;}` : "")}
`;

const RowClone = styled(StyledFlexRow)`
  opacity: 0.5;
  ~ div {
    transform: none !important;
  }
`;

export interface FlexBoxRowRendererProps<T extends object = any> {
  columns: FlexBoxColumnProps<T>[];
  striped?: boolean;
  index: number;
  rowData: T;
  className?: string;
  selectionPredicate?: (T) => boolean;
  draggableId?: (rowData: T, index: number) => string;
  keepRowSpaceWhenDragging?: boolean;
  onRowClick?: (props: { index: number; rowData: T }) => void;
}

/**
 * Row renderer supportung draggable cells
 * @param props
 */
export const DraggableFlexBoxRowRenderer: React.FC<
  FlexBoxRowRendererProps<SipShopCoreServicesVoItem>
> = props => {
  const onClick = useCallback(
    () =>
      props.onRowClick &&
      props.onRowClick({ index: props.index, rowData: props.rowData }),
    [props]
  );
  return (
    <Draggable
      isDragDisabled={false}
      draggableId={
        props.draggableId
          ? props.draggableId(props.rowData, props.index)
          : props.rowData.id
      }
      index={props.index}
    >
      {(provided, snapshot) => {
        const isSelected = props.selectionPredicate
          ? props.selectionPredicate(props.rowData)
          : false;

        const content = props.columns.map((column, index) => (
          <FlexBoxCellRenderer
            rowIndex={props.index}
            key={index}
            rowData={props.rowData}
            columnProps={column}
            data={column.dataKey && props.rowData[column.dataKey]}
          />
        ));

        return (
          <>
            <StyledFlexRow
              isDragging={snapshot.isDragging}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              className={props.className}
              isActive={isSelected}
              onClick={onClick}
            >
              {content}
            </StyledFlexRow>
            {props.keepRowSpaceWhenDragging && snapshot.isDragging && (
              <RowClone>{content}</RowClone>
            )}
          </>
        );
      }}
    </Draggable>
  );
};

const DefaultFlexBoxRowRenderer = React.forwardRef<
  HTMLDivElement,
  FlexBoxRowRendererProps
>((props, ref) => {
  const onClick = useCallback(
    () =>
      props.onRowClick &&
      props.onRowClick({ index: props.index, rowData: props.rowData }),
    [props]
  );
  return (
    <StyledFlexRow ref={ref} striped={props.striped} onClick={onClick}>
      {props.columns.map((column, index) => (
        <FlexBoxCellRenderer
          key={index}
          rowData={props.rowData}
          rowIndex={props.index}
          columnProps={column}
          data={column.dataKey && props.rowData[column.dataKey]}
        />
      ))}
    </StyledFlexRow>
  );
});

export default DefaultFlexBoxRowRenderer;
