import i18next from "i18next";
import { ThunkDispatch } from "redux-thunk";
import { localeStringConverter } from "../../i18n";
import { asyncActionWrapper, getOrderService } from "../../services/api";
import {
  SipShopCoreServicesVoLabeledData,
  SipShopCoreServicesVoOrder
} from "../../services/productCatalogue";
import { defaultTimeFormat, parseFloatFromLocalizedStr } from "../../utils";
import { actionCreator, ReduxRootType } from "../store";
import { ErrorType } from "./error";
import { Subtract } from "./shop";

export const SET_ORDERS = "SET_ORDERS";
export const SET_ORDERS_SORT = "SET_ORDERS_SORT";
export const SET_ORDERS_MATID_SEARCH = "SET_ORDERS_MATID_SEARCH";
export const SET_ORDERS_QUERY = "SET_ORDERS_QUERY";
export const SET_ORDER_DETAIL = "SET_ORDER_DETAIL";
export const SET_ORDER_EDIT = "SET_ORDER_EDIT";
export const SET_ORDER_EDIT_MOD_VAL = "SET_ORDER_EDIT_MOD_VAL";
export const SET_ORDER_EDIT_MOD_QTY = "SET_ORDER_EDIT_MOD_QTY";

export interface ItemDeleteModification {
  posNo: number | string;
  matId: string;
}

export interface ItemQtyChanged extends ItemDeleteModification {
  old: number | string;
  new: number | string;
}

export interface OrderModificationState {
  isDirty?: boolean;
  qtyChanges?: ItemQtyChanged[];
  completeDelivery?: boolean;
  deliveryDate?: number;
  deliveryAddress?: SipShopCoreServicesVoLabeledData;
  deliveryType?: SipShopCoreServicesVoLabeledData;
}

export interface OrderState {
  entries: SipShopCoreServicesVoOrder[] | undefined | null;
  sort?: { key: keyof SipShopCoreServicesVoOrder; direction: "ASC" | "DESC" };
  filter?: {
    query: string;
    results: SipShopCoreServicesVoOrder[];
    productSearch: boolean;
  };
  detail?: SipShopCoreServicesVoOrder | null;
  editMode: boolean;
  modifications?: OrderModificationState;
  searchLoading?: boolean;
}

const initialState: OrderState = {
  entries: undefined,
  editMode: false
};

export const setOrders = (
  entries: SipShopCoreServicesVoOrder[] | undefined | null
) => actionCreator(SET_ORDERS, entries);

export const setOrdersMatIdSearch = (
  entries: SipShopCoreServicesVoOrder[] | undefined | null
) => actionCreator(SET_ORDERS_MATID_SEARCH, entries);

export const setOrdersSort = (
  key: keyof SipShopCoreServicesVoOrder,
  direction: "ASC" | "DESC"
) => actionCreator(SET_ORDERS_SORT, { key, direction });

export const setOrdersQuery = (query: string | undefined) =>
  actionCreator(SET_ORDERS_QUERY, query);

export const setOrderEdit = (edit: boolean) =>
  actionCreator(SET_ORDER_EDIT, edit);

export const setOrderModValue = (mod: OrderModificationState) =>
  actionCreator(SET_ORDER_EDIT_MOD_VAL, mod);
export const setOrderModQty = (mod: ItemQtyChanged) =>
  actionCreator(SET_ORDER_EDIT_MOD_QTY, mod);
export const setOrderModDel = (
  mod: Subtract<ItemQtyChanged, { new: number }>
) => actionCreator(SET_ORDER_EDIT_MOD_QTY, { ...mod, new: 0 });

export const setOrderDetail = (
  order: SipShopCoreServicesVoOrder | undefined | null
) => actionCreator(SET_ORDER_DETAIL, order);

export type OrderActions = ReturnType<
  | typeof setOrders
  | typeof setOrdersSort
  | typeof setOrdersQuery
  | typeof setOrderDetail
  | typeof setOrderEdit
  | typeof setOrderModDel
  | typeof setOrderModQty
  | typeof setOrderModValue
  | typeof setOrdersMatIdSearch
>;

const hasModifications = (mods?: OrderModificationState) => {
  let isDirty = false;
  if (!mods) {
    return isDirty;
  }
  for (let k of Object.keys(mods)) {
    if (mods.hasOwnProperty(k)) {
      return true;
    }
  }
};

const orderReducer = (
  state = initialState,
  action: OrderActions
): OrderState => {
  switch (action.type) {
    case SET_ORDER_EDIT:
      return {
        ...state,
        editMode: action.payload,
        modifications: {}
      };
    case SET_ORDER_EDIT_MOD_VAL:
      const modifications = state.modifications
        ? {
            ...state.modifications,
            ...action.payload
          }
        : action.payload;
      return {
        ...state,
        modifications: {
          ...modifications,
          isDirty: hasModifications(modifications)
        }
      };
    case SET_ORDER_EDIT_MOD_QTY:
      if (!state.modifications) {
        return state;
      }
      // filter out old changes
      const qtyChanges = state.modifications.qtyChanges
        ? state.modifications.qtyChanges.filter(
            item => item.posNo !== action.payload.posNo
          )
        : [];

      // parse and compare values
      const oldVal =
        typeof action.payload.old === "string"
          ? parseFloatFromLocalizedStr(action.payload.old)
          : action.payload.old;
      const newVal =
        typeof action.payload.new === "string"
          ? parseFloatFromLocalizedStr(action.payload.new)
          : action.payload.new;

      // if (oldVal === newVal) {
      //   console.debug("Values did not change", { action, oldVal, newVal });
      //   return state;
      // }

      qtyChanges.push({ ...action.payload, new: newVal, old: oldVal });
      return {
        ...state,
        modifications: { ...state.modifications, qtyChanges, isDirty: true }
      };
    case SET_ORDERS_MATID_SEARCH:
      const results = action.payload ? action.payload : [];
      return {
        ...state,
        filter: state.filter
          ? {
              ...state.filter,
              productSearch: true,
              results
            }
          : { query: "", productSearch: true, results }
      };
    case SET_ORDER_DETAIL:
      return {
        ...state,
        detail: action.payload
      };
    case SET_ORDERS:
      return { ...state, entries: action.payload };
    case SET_ORDERS_QUERY:
      const query = action.payload ? action.payload.trim() : undefined;
      return {
        ...state,
        filter:
          query && query.length > 0
            ? {
                query: query,
                results: filterEntries(state.entries!, query),
                productSearch: false
              }
            : undefined
      };
    case SET_ORDERS_SORT:
      if (!state.entries) {
        return state;
      }
      // TODO: fix sorting for labeled data and strings
      const sortedEntries = sortByKey(
        action.payload.key,
        action.payload.direction,
        state.entries
      );
      return {
        ...state,
        entries: sortedEntries,
        sort: { key: action.payload.key, direction: action.payload.direction }
      };
    default:
      return state;
  }
};

export const selectOrder = (orderId: string | undefined) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  if (!orderId) {
    dispatch(setOrderDetail(undefined));
    return;
  }
  const call = getOrderService().operations.getOrderDetails(orderId);
  try {
    const order = (await asyncActionWrapper(
      call,
      dispatch,
      ErrorType.addToCart
    )) as SipShopCoreServicesVoOrder;
    dispatch(setOrderDetail(order));
  } catch (e) {}
};

/**
 * Utils
 */

export const sortByKey = (
  key: keyof SipShopCoreServicesVoOrder,
  direction: "ASC" | "DESC",
  entries: SipShopCoreServicesVoOrder[]
): SipShopCoreServicesVoOrder[] => {
  switch (key) {
    case "deliveryDate":
    case "statusUpdated":
    case "sapOrderId":
    case "favouredDeliveryDate":
    case "total":
    case "id":
      return entries.sort((a, b) =>
        direction === "ASC"
          ? parseInt(a[key]) - parseInt(b[key])
          : parseInt(b[key]) - parseInt(a[key])
      );
    case "name":
    case "status":
      return entries.sort((a, b) =>
        direction === "ASC"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key])
      );
    case "owner":
    case "company":
    case "deliveryAddress":
    case "deliveryType":
      return entries.sort((a, b) =>
        direction === "ASC"
          ? a[key].label.localeCompare(b[key].label)
          : b[key].label.localeCompare(a[key].label)
      );
    default:
      return entries;
  }
};

export const filterEntries = (
  entries: SipShopCoreServicesVoOrder[],
  query: string,
  locale: string = localeStringConverter(i18next.language)
) =>
  entries.filter(
    item =>
      (item.id && typeof item.id === 'string' && item.id.indexOf(query) !== -1) ||
      (item.sapOrderId && item.sapOrderId.indexOf(query) !== -1) ||
      item.company.label.indexOf(query) !== -1 ||
      item.company.label.toLocaleLowerCase().indexOf(query) !== -1 ||
      item.deliveryAddress.label.indexOf(query) !== -1 ||
      item.deliveryAddress.label.toLocaleLowerCase().indexOf(query) !== -1 ||
      item.name.indexOf(query) !== -1 ||
      item.name.toLocaleLowerCase().indexOf(query) !== -1 ||
      item.owner.label.indexOf(query) !== -1 ||
      item.owner.label.toLocaleLowerCase().indexOf(query) !== -1 ||
      new Date(parseInt(item.statusUpdated) * 1000)
        .toLocaleDateString(locale, defaultTimeFormat)
        .indexOf(query) !== -1 ||
      new Date(parseInt(item.deliveryDate) * 1000)
        .toLocaleDateString(locale, defaultTimeFormat)
        .indexOf(query) !== -1
  );

export default orderReducer;
