import {connectRouter} from 'connected-react-router';
import {History} from 'history';
import {combineReducers} from 'redux';

import bottomCart from './bottomCart';
import cart from './cart';
import errors from './error';
import messages from './messages';
import orders from './order';
import search from './search';
import shop from './shop';
import toasts from './toasts';
import user from './user';

export const rootReducer = (history: History) => combineReducers({
  user,
  errors,
  shop,
  cart,
  toasts,
  messages,
  bottomCart,
  search,
  orders,
  router: connectRouter(history)
});
